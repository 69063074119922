import { ExportConfigFormHelper } from './abstract-export-template-form-helper';
import { ConnectorMode } from './export-connector.entity';
import { ExportFormat } from './export-template-details.entity';
import { CsvExportFormHelper } from './export-template.form-csv-helper';
import { DatabaseExportFormHelper } from './export-template.form-database-helper';
import { ExcelExportFormHelper } from './export-template.form-excel-helper';
import { JsonExportFormHelper } from './export-template.form-json-helper';
import { ParquetExportFormHelper } from './export-template.form-parquet-helper';
import { XmlExportFormHelper } from './export-template.form-xml-helper';

export abstract class ExportTemplateFormHelper {
	public static buildWith(
		connector: ConnectorMode,
		exportType: ExportFormat
	): ExportConfigFormHelper {
		const isDb =
			connector === ConnectorMode.SQL ||
			connector === ConnectorMode.NoSQL ||
			connector === ConnectorMode.Neo4j;
		if (isDb) {
			return new DatabaseExportFormHelper();
		} else if (exportType === ExportFormat.CSV) {
			return new CsvExportFormHelper();
		} else if (exportType === ExportFormat.Excel) {
			return new ExcelExportFormHelper();
		} else if (exportType === ExportFormat.XML) {
			return new XmlExportFormHelper();
		} else if (exportType === ExportFormat.JSON) {
			return new JsonExportFormHelper();
		} else if (exportType === ExportFormat.Parquet) {
			return new ParquetExportFormHelper();
		}
		throw new Error('Could not instantiate helper');
	}

	private constructor() {}
}
