import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, shareReplay } from 'rxjs';

import { AjsInjectorService } from './ajs-injector.service';

@Injectable({
	providedIn: 'root',
})
export class NgCommService {
	private readonly ngCommService: Observable<any>;

	public constructor(private readonly ajsInjectorService: AjsInjectorService) {
		this.ngCommService = this.ajsInjectorService.$injector.pipe(
			map(($injector) => $injector.get('ngCommService')),
			shareReplay(1)
		);
	}

	public async openPathExplorerModal(options: unknown): Promise<string> {
		const ngCommService = await firstValueFrom(this.ngCommService);
		return ngCommService.openPathExplorerModal(options);
	}

	public async notifyOnError(str: string): Promise<void> {
		const ngCommService = await firstValueFrom(this.ngCommService);
		return ngCommService.notifyOnError(str);
	}

	public async notifyOnWarning(str: string): Promise<void> {
		const ngCommService = await firstValueFrom(this.ngCommService);
		return ngCommService.notifyOnWarning(str);
	}

	public async notifyOnSuccess(str: string): Promise<void> {
		const ngCommService = await firstValueFrom(this.ngCommService);
		return ngCommService.notifyOnSuccess(str);
	}
}
