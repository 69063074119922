import { IComparable } from '@dc-common-core';
import { immerable } from 'immer';

export enum ConnectorMode {
	None = '',
	Local = 'local',
	HDFS = 'hdfs',
	SFTP = 'sftp',
	HTTP = 'http',
	S3 = 'S3',
	SQL = 'Sql',
	NoSQL = 'NoSql',
	Neo4j = 'Neo4j',
}

interface IExportConnectorEntity {
	id: number;
	label: string;
	type: ConnectorMode;
}

const entityDefaults: IExportConnectorEntity = {
	id: -1,
	label: 'Non existing Local connector',
	type: ConnectorMode.Local,
};

export class ExportConnectorEntity
	implements IExportConnectorEntity, IComparable<ExportConnectorEntity>
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public label = entityDefaults.label;
	public type = entityDefaults.type;

	public static build(
		params: Partial<IExportConnectorEntity> = {}
	): ExportConnectorEntity {
		const instance = new ExportConnectorEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.type = params.type ?? entityDefaults.type;
		instance.label = params.label ?? entityDefaults.label;
		return instance;
	}

	public static localConnector(): ExportConnectorEntity {
		return ExportConnectorEntity.build({
			id: 0,
			type: ConnectorMode.Local,
			label: 'Local',
		});
	}

	public static generaleLocalConnector(label: string): ExportConnectorEntity {
		return this.build({
			type: ConnectorMode.Local,
			label,
		});
	}

	private constructor() {}

	public compareTo(obj: ExportConnectorEntity): number {
		return this.id - obj.id;
	}
}
