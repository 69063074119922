import { Inject, Injectable } from '@angular/core';
import { TokenInterceptor, WINDOW } from '@dc-common-core';

@Injectable()
export class DcTokenInterceptor extends TokenInterceptor {
	public constructor(@Inject(WINDOW) private readonly window: Window) {
		super();
	}
	protected setBearerToken(): string {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return `Bearer ${this.window._keycloak.token}`;
	}
}
