import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineAll } from '@dc-common-core';
import { AppModalService } from '@dc-common-ui';
import notify from 'devextreme/ui/notify';
import {
	Observable,
	ReplaySubject,
	interval,
	map,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs';

import { DcIcons } from '../../app-dc.icons';
import { GdIcons } from '../../app-gd.icons';
import { HdIcons } from '../../app-hd.icons';
import { TestUserComponent } from '../test-user/test-user.component';
import { DemoService, TEMP_MAX, TEMP_MIN, Temperature } from './demo.service';

@Component({
	selector: 'app-test',
	templateUrl: './demo.component.html',
	styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnInit, OnDestroy {
	private readonly destroy$ = new ReplaySubject<void>(1);
	public DcIcons = DcIcons;
	public GdIcons = GdIcons;
	public HdIcons = HdIcons;

	public lowTemp = TEMP_MIN;
	public highTemp = TEMP_MAX;
	public vo$: Observable<{
		temperaturesData: Array<Temperature>;
		translatedString: string;
		currentTime: string;
		currentDate: string;
	}>;

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public customizePoint = (arg: any): any => {
		if (arg.value > this.highTemp) {
			return {
				color: '#ff7c7c',
				hoverStyle: {
					color: '#ff7c7c',
				},
			};
		}
		if (arg.value < this.lowTemp) {
			return {
				color: '#8c8cff',
				hoverStyle: {
					color: '#8c8cff',
				},
			};
		}
	};
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public customizeLabel = (arg: any): any => {
		if (arg.value > this.highTemp) {
			return {
				visible: true,
				backgroundColor: '#ff7c7c',
				customizeText(e: any) {
					return `${e.valueText}&#176C`;
				},
			};
		}
	};
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public customizeText = (arg: any): string => `${arg.valueText}&#176C`;
	// @ts-ignore
	public constructor(
		private readonly demoService: DemoService,
		private readonly modalService: AppModalService
	) {
		interval(3000)
			.pipe(
				takeUntil(this.destroy$),
				tap(() => this.demoService.generateRandomTemperature())
			)
			.subscribe();

		this.vo$ = interval(1000).pipe(
			map((_) => new Date().toLocaleTimeString()),
			switchMap((time) =>
				combineAll({
					currentDate: new Date().toDateString(),
					currentTime: time,
					temperaturesData: this.demoService.temperature$,
					translatedString: $localize`:i18n=@@another.test:`,
				})
			)
		);
	}
	public openModal(): void {
		this.modalService.openModal(TestUserComponent, {
			title: 'test',
			subTitle: 'test subtitle',
		});
	}

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore

	public ngOnInit(): void {}

	public notify($event: string): void {
		notify('The button was clicked');
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
	}
}
