<ng-container>
	<form
		class="app-export-xml-configuration"
		autocomplete="off"
		[formGroup]="form">
		<mat-form-field
			id="{{ cmpId + 'groupTag-input' }}"
			[appearance]="Appearance">
			<mat-label i18n="@@export.xml.groupTag">Group tag name</mat-label>
			<input
				type="text"
				matInput
				placeholder="GROUP_TAG_PLACEHOLDER"
				i18n-placeholder="@@export.xml.groupTag.placeholder"
				[formControlName]="ExportXmlFormControls.GroupTag" />
			<mat-error
				*ngIf="
					form
						.get(ExportXmlFormControls.GroupTag)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@export.xml.groupTag.error.required">
				Valeur obligatoire
			</mat-error>
		</mat-form-field>

		<mat-form-field
			id="{{ cmpId + '-childTag-input' }}"
			[appearance]="Appearance">
			<mat-label i18n="@@export.xml.childTag">Child tag name</mat-label>
			<input
				type="text"
				matInput
				placeholder="CHILD_TAG_PLACEHOLDER"
				i18n-placeholder="@@export.xml.childTag.placeholder"
				[formControlName]="ExportXmlFormControls.ChildTag" />
			<mat-error
				*ngIf="
					form
						.get(ExportXmlFormControls.ChildTag)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@export.xml.childTag.error.required">
				Valeur obligatoire
			</mat-error>
		</mat-form-field>
	</form>
</ng-container>
