import { Injectable } from '@angular/core';
import { AppIcons, IIcons } from '@dc-common-ui';

export enum GdIcons {
	PersistInMemory = 'Generics:persist-memory',
	PersistInDisk = 'Generics:persist-disk',
	Search = 'Generics:search',
}

@Injectable()
export class AppGdIcons extends AppIcons {
	private readonly path = './src/img';
	protected namespace = 'Generics';

	protected icons: IIcons = {
		[GdIcons.PersistInMemory]: `${this.path}/gd/persist-memory.svg`,
		[GdIcons.PersistInDisk]: `${this.path}/gd/persist-disk.svg`,
		[GdIcons.Search]: `${this.path}/svg/icon-preview.svg`,
	};
}
