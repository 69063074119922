import { ActionReducerMap } from '@ngrx/store';

import { coreReducer } from './app.reducer';

export const CORE = 'core';

export interface IAppRootState {
	[CORE]: unknown;
}

export const rootReducer: ActionReducerMap<IAppRootState> = {
	[CORE]: coreReducer,
};
