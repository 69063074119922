import { Injectable } from '@angular/core';
import { Map } from 'immutable';

import { ProjectStorage } from '../core/project.storage';
import {
	IFormulaUsage,
	IUsageInCarac,
	IUsageInDB,
	IUsageInSource,
} from '../requesters/formula/formula-usages.models';
import { FormulaUsageListEntity } from './components/formula-usage-list/formula-usage-list.entity';
import {
	FormulaUsageDetails,
	FormulaUsageEntity,
} from './components/formula-usage-list/formula-usage.entity';

enum HDType {
	MAP = 'MAP',
	CHART = 'CHART',
	TIMELINE = 'TIMELINE',
	GRID = 'GRID',
	MEDIA = 'MEDIA',
}

const FORMULA_NAME_SEPARATOR = '.';

@Injectable()
export class FormulaParser {
	public constructor(private readonly projectStorage: ProjectStorage) {}

	public parseFormulaUsages(
		response: ReadonlyArray<IFormulaUsage>
	): FormulaUsageListEntity {
		const list = this.generateFormulaListConfig();

		const tuples = response.reduce((acc, curr, idx) => {
			const [cat, ...trail] = curr.formula_name.split(FORMULA_NAME_SEPARATOR);
			const name = trail.reduce<string>((acc, curr) => {
				if (acc === '') {
					return curr;
				}
				return `${acc}${FORMULA_NAME_SEPARATOR}${curr}`;
			}, '');

			const usage = FormulaUsageEntity.build({
				id: idx,
				label: name,
				category: cat,
				count: curr.usage_count,
				isDeprecated: curr.deprecated,
				datablock: this.parseUsagesInDatablocks(curr.usages.usages_in_dbs),
				hdSource: this.parseUsagesInSources(curr.usages.usages_in_sources),
				entityCharacteristic: this.parseUsagesInEntityCharacteristic(
					curr.usages.usages_in_caracs
				),
				entityLink: this.parseUsageInEntityLinks(
					curr.usages.usages_in_entites_links
				),
			});
			acc.push([curr.formula_name, usage]);
			return acc;
		}, new Array<[string, FormulaUsageEntity]>());

		return list.setElements(Map(tuples));
	}

	public getAllFormulaUsageCount(
		response: ReadonlyArray<IFormulaUsage>
	): number {
		return response.reduce((total, usage) => total + usage.usage_count, 0);
	}

	private parseUsagesInDatablocks(
		res: ReadonlyArray<IUsageInDB>
	): FormulaUsageDetails {
		return res.map((db) => {
			const step = $localize`:i18n=@@formula.usage.in.datablock.step:`;
			return {
				url: this.parseDatablockPageUrl(db.datablock_id),
				label: `${db.datablock_label} - ${step} : ${db.step_index}`,
			};
		});
	}

	private parseUsagesInSources(
		res: ReadonlyArray<IUsageInSource>
	): FormulaUsageDetails {
		return res.map((source) => {
			const step = $localize`:i18n=@@formula.usage.in.datablock.step:`;
			return {
				url: this.parseSourcePageUrl(source),
				label: `${source.source_label} - ${step} : ${source.step_index} - ${source.element_label}`,
			};
		});
	}

	private parseUsagesInEntityCharacteristic(
		res: ReadonlyArray<IUsageInCarac>
	): FormulaUsageDetails {
		return res.map((item) => ({
			url: this.parseEntityPageUrl(item.entite_type_id),
			label: `${item.carac_label} (${item.entite_type_label})`,
		}));
	}

	private parseUsageInEntityLinks(
		res: ReadonlyArray<IUsageInCarac>
	): FormulaUsageDetails {
		return res.map((item) => ({
			url: this.parseEntityPageUrl(item.entite_type_id),
			label: `${item.carac_label} (${item.entite_type_label})`,
		}));
	}

	private parseDatablockPageUrl(datablockId: number): string {
		const projectId = this.projectStorage.getValue()?.projectId;
		return `/#/project/${projectId}/datablocks/edit/${datablockId}`; // FIXME: derive from state
	}

	private parseEntityPageUrl(entityId: number): string {
		const projectId = this.projectStorage.getValue()?.projectId;
		return `/#/project/${projectId}/entity/edit/${entityId}`; // FIXME: derive from state
	}

	private generateFormulaListConfig(): FormulaUsageListEntity {
		return FormulaUsageListEntity.build([
			{
				field: 'label',
				alias: $localize`:@@formula:`,
				type: 'string',
				isFilterable: true,
			},
			{
				field: 'count',
				alias: $localize`:@@formula.count:`,
				type: 'string',
				isFilterable: true,
			},
			{
				field: 'category',
				alias: 'Category',
				type: 'string',
				groupIdx: 1,
				isFilterable: true,
			},
			{
				field: 'datablock',
				alias: $localize`:@@formula.usage.in.datablock:`,
				type: 'string',
			},
			{
				field: 'entityLink',
				alias: $localize`:@@formula.usage.in.entities.links:`,
				type: 'string',
			},
			{
				field: 'entityCharacteristic',
				alias: $localize`:@@formula.usage.in.entities:`,
				type: 'string',
			},
			{
				field: 'hdSource',
				alias: $localize`:@@formula.usage.in.hd:`,
				type: 'string',
			},
		]);
	}

	private parseSourcePageUrl(source: IUsageInSource): string {
		const projectId = this.projectStorage.getValue()?.projectId;

		const {
			type: elementType,
			element_id: elementId,
			sub_type: elementSubType,
		} = source;
		// FIXME: derive from state
		switch (elementType) {
			case HDType.MAP:
				return `/#/project/${projectId}/map/edit/${elementId}`;
			case HDType.CHART:
				return `/#/project/${projectId}/chart/${elementSubType}/edit/${elementId}`;
			case HDType.GRID:
				return `/#/project/${projectId}/grid/${elementSubType}/edit/${elementId}`;
			case HDType.TIMELINE:
				return `/#/project/${projectId}/timeline/edit/${elementId}`;
			case HDType.MEDIA:
				return `/#/project/${projectId}/media/edit/${elementId}`;
			default:
				return '/#/project/';
		}
	}
}
