import { IisEqual } from '@dc-common-core';
import { immerable } from 'immer';

interface IExportXmlConfigurationEntity {
	groupTag: string;
	childTag: string;
}

const entityDefaults: IExportXmlConfigurationEntity = {
	groupTag: 'rows',
	childTag: 'row',
};

export class ExportXmlConfigurationEntity
	implements
		IExportXmlConfigurationEntity,
		IisEqual<ExportXmlConfigurationEntity>
{
	private [immerable] = true;
	public groupTag = entityDefaults.groupTag;
	public childTag = entityDefaults.childTag;

	public static build(
		params: Partial<IExportXmlConfigurationEntity> = {}
	): ExportXmlConfigurationEntity {
		const instance = new ExportXmlConfigurationEntity();
		instance.groupTag = params.groupTag ?? entityDefaults.groupTag;
		instance.childTag = params.childTag ?? entityDefaults.childTag;
		return instance;
	}
	private constructor() {}

	public isEqualTo(obj: ExportXmlConfigurationEntity): boolean {
		if (this.groupTag.localeCompare(obj.groupTag) !== 0) {
			return false;
		}
		if (this.childTag.localeCompare(obj.childTag) !== 0) {
			return false;
		}
		return true;
	}
}
