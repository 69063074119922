import { Injectable } from '@angular/core';
import { UrlService } from '@dc-common-core';
import { Observable } from 'rxjs';

import { DcBaseRequester } from './dc-base.requester';
import { IProjectModel } from './project.model';

@Injectable({
	providedIn: 'root',
})
export class ProjectRequester extends DcBaseRequester<IProjectModel> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;
	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('PROJECTS_GET_ALL');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public getOrphanProjects(): Observable<ReadonlyArray<IProjectModel>> {
		const url = this.urlService.generateUrl(
			this.generateResourceUrl('PROJECTS_ORPHAN'),
			{},
			{}
		);
		return this.get<Array<IProjectModel>>(url);
	}

	public setOrphanProjectAdmins(
		id: number,
		admins: Array<string>
	): Observable<void> {
		const url = this.urlService.generateUrl(
			this.generateResourceUrl('PROJECTS_ADMIN_MEMBERS'),
			{
				id,
			},
			{}
		);
		return this.http.post<void>(url, admins);
	}
}
