import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs';

import { FormulaRequester } from '../../requesters/formula/formula.requester';
import { FormulaParser } from '../formula.parser';
import {
	fetchFormulaUsage,
	fetchFormulaUsageFail,
	fetchFormulaUsageSuccess,
} from './formula.actions';

@Injectable()
export class FormulaEffects {
	public formulaUsages$ = createEffect(() =>
		this.action$.pipe(
			ofType(fetchFormulaUsage),
			switchMap(() => this.formulaRequester.getFormulasUsages()),
			map((res) =>
				fetchFormulaUsageSuccess({
					elements: this.formulaParser.parseFormulaUsages(res),
					allUsageCount: this.formulaParser.getAllFormulaUsageCount(res),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(
					fetchFormulaUsageFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public constructor(
		private readonly action$: Actions,
		private readonly formulaRequester: FormulaRequester,
		private readonly store: Store,
		private readonly formulaParser: FormulaParser
	) {}
}
