import { Injectable } from '@angular/core';
import { UrlService } from '@dc-common-core';
import { Observable } from 'rxjs';

import { IConnectorModel } from './connector.model';
import { DcBaseRequester } from './dc-base.requester';

@Injectable({
	providedIn: 'root',
})
export class ConnectorRequester extends DcBaseRequester<IConnectorModel> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;
	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('CONNECTORS');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public getAvailableConnectors(
		excludedTypes: Array<string>
	): Observable<Array<IConnectorModel>> {
		const url = this.urlService.generateUrl(
			`${this.resource}`,
			{},
			{
				details: true,
				project: this.projectId,
				excludedTypes,
			}
		);
		return this.get<Array<IConnectorModel>>(url);
	}
}
