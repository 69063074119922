import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppIcons, DcLibUiModule } from '@dc-common-ui';
import { NgxColorsModule } from 'ngx-colors';

import { AppDcIcons } from './app-dc.icons';
import { AppGdIcons } from './app-gd.icons';
import { AppHdIcons } from './app-hd.icons';
import { AppMetadataComponent } from './components/app-metadata/app-metadata.component';
import { AppTagConfiguratorComponent } from './components/app-tag-configurator/app-tag-configurator.component';
import { AppTagEditComponent } from './components/app-tag-edit/app-tag-edit.component';
import { DemoComponent } from './components/demo/demo.component';
import { AppNavDocComponent } from './components/nav-doc/nav-doc.component';
import { TestUserComponent } from './components/test-user/test-user.component';
import { DxtremeModule } from './dxtreme.module';
import { MaterialModule } from './material.module';

export const DcIconsInjectable = new InjectionToken('dc default namespace', {
	factory: (): AppIcons =>
		new AppDcIcons(inject(MatIconRegistry), inject(DomSanitizer)),
});

export const GdIconsInjectable = new InjectionToken('Generics data namespace', {
	factory: (): AppIcons =>
		new AppGdIcons(inject(MatIconRegistry), inject(DomSanitizer)),
});

export const HdIconsInjectable = new InjectionToken('Handle data namespace', {
	factory: (): AppIcons =>
		new AppHdIcons(inject(MatIconRegistry), inject(DomSanitizer)),
});

const availableComponents = [
	DemoComponent,
	TestUserComponent,
	AppMetadataComponent,
	AppNavDocComponent,
	AppTagConfiguratorComponent,
	AppTagEditComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		DcLibUiModule,
		MaterialModule,
		DxtremeModule,
		FormsModule,
		NgxColorsModule,
	],
	providers: [],
	declarations: availableComponents,
	exports: availableComponents,
})
export class UiModule {}
