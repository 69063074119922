import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { IAppRootState } from '../../core/store';
import { OrphanProjectListEntity } from '../components/orphan-project-list/orphan-project-list.entity';
import {
	OrphanProjectFeatureKey,
	OrphanProjectState,
} from './orphan-project.state';

interface OrphanProjectRootState extends IAppRootState {
	[OrphanProjectFeatureKey]: OrphanProjectState;
}

@Injectable()
export class OrphanProjectSelector {
	public constructor(private readonly store: Store<OrphanProjectRootState>) {}

	private get state(): Observable<OrphanProjectState> {
		return this.store.pipe(select((store) => store[OrphanProjectFeatureKey]));
	}
	public getLoading$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('loading')));
	}

	public getOrphanProjectList$(): Observable<OrphanProjectListEntity> {
		return this.state.pipe(map((state) => state.get('orphanProjectList')));
	}
}
