<form class="app-metadata-form"  [formGroup]="metadataForm.form">
	<mat-form-field [appearance]="Appearance" class="app-metadata-form__title">
		<mat-label i18n="@@metadata.title">Titre</mat-label>
		<input
			id="{{ cmpId }}-title"
			type="text"
			matInput
			[placeholder]="titlePlaceHolder"
			[formControlName]="MetadataFormControls.Title"/>

		<mat-error
			*ngIf="metadataForm.form.get(MetadataFormControls.Title)?.hasError(ValidationErrorKeys.Required)"
			i18n="@@general.form.errors.required">
			You must make a selection
		</mat-error>
	</mat-form-field>

	<mat-form-field [appearance]="Appearance" class="app-metadata-form__description">
		<mat-label i18n="@@metadata.description">Description</mat-label>
		<input
			id="{{ cmpId }}-description"
			type="text"
			matInput
			[placeholder]="descriptionPlaceHolder"
			[formControlName]="MetadataFormControls.Description"/>
	</mat-form-field>

	<div class="app-metadata-form__status">
		<mat-label i18n="@@metadata.activeState">activeState</mat-label>
		<mat-slide-toggle
			id="{{cmpId}}-active-state"
			[formControlName]="MetadataFormControls.ActiveState"
		></mat-slide-toggle>
	</div>

	<div class="app-metadata-form__icon">
<!--		<img src="https://placehold.co/300x300?font=Montserrat" alt="export-template-img">-->
	</div>

	<div class="app-metadata-form__tags">
		<ng-content select="app-tag-configurator"></ng-content>
	</div>
</form>
