import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Map } from 'immutable';

import { ExportTemplateDetailsEntity } from './components/export-template-details/export-template-details.entity';
import { ExportTemplateListItemEntity } from './components/export-template-list/export-template-list-item.entity';
import {
	configureSelectedDatablockExport,
	deleteExportTemplatesById,
	ExportTemplateSelector,
	fetchExportTemplateList,
} from './store';

@Injectable()
export class NgExportTemplate {
	public constructor(
		private readonly store: Store,
		private readonly exportTemplateSelector: ExportTemplateSelector
	) {}

	public refreshAvailableExportTemplateList(
		excludePublic: boolean,
		excludeInactive: boolean
	): void {
		this.store.dispatch(
			fetchExportTemplateList({
				excludePublic,
				excludeInactive,
			})
		);
	}

	public getAvailableExportTemplateList(): Promise<
		Map<number, ExportTemplateListItemEntity<ExportTemplateDetailsEntity>>
	> {
		return this.exportTemplateSelector.getExportTemplateList();
	}

	public deleteTemplatesById(
		templateIds: Array<number>,
		excludePublic: boolean,
		excludeInactive: boolean
	): void {
		this.store.dispatch(
			deleteExportTemplatesById({
				elemIds: templateIds,
				excludePublic,
				excludeInactive,
			})
		);
	}

	public configureSelectedDatablockExport(
		datablockId: number,
		datablockLabel: string
	): void {
		this.store.dispatch(
			configureSelectedDatablockExport({
				datablockId,
				datablockLabel,
			})
		);
	}
}
