import { createAction, props } from '@ngrx/store';

import { FormulaUsageListEntity } from '../components/formula-usage-list/formula-usage-list.entity';

export enum FormulaActions {
	FetchFormulaUsage = '[BE][Formula Usage] fetch formulas usages across datablocks, entities, links and sources',
	FetchFormulaUsageSuccess = '[BE][Formula Usage] fetch formulas usages success',
	FetchFormulaUsageFail = '[BE][Formula Usage] fetch formulas usages failed',
	NavigateToReferencedPage = '[Formula Usage] Navigate to referenced link',
}

export const fetchFormulaUsage = createAction(FormulaActions.FetchFormulaUsage);

export const fetchFormulaUsageSuccess = createAction(
	FormulaActions.FetchFormulaUsageSuccess,
	props<{ elements: FormulaUsageListEntity; allUsageCount: number }>()
);

export const fetchFormulaUsageFail = createAction(
	FormulaActions.FetchFormulaUsageFail,
	props<{ error: unknown }>()
);

export const traceNavigateToReferenced = createAction(
	FormulaActions.NavigateToReferencedPage,
	props<{ url: string; referenced: string }>()
);
