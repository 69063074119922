import { Injectable } from '@angular/core';
import { CachePolicy, CachePolicyEntity, UrlService } from '@dc-common-core';
import { Observable } from 'rxjs';

import { ISupportedCharset } from './charset.model';
import { DcBaseRequester } from './dc-base.requester';

@Injectable({
	providedIn: 'root',
})
export class CharsetRequester extends DcBaseRequester<ISupportedCharset> {
	protected resource: string;
	protected elementsListUrl;
	protected singleElementUrl;
	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('CHARSETS');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = `${this.elementsListUrl}/{templateId}`;
	}

	public getAvailableCharsets(): Observable<ReadonlyArray<ISupportedCharset>> {
		const url = this.urlService.generateUrl(this.resource, {}, {});
		return this.get<ReadonlyArray<ISupportedCharset>>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.CacheOnly,
				evictTimeout: 30 * 60,
				isCacheable: true,
			})
		);
	}
}
