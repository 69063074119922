<div *ngIf="vo$ | async; let vo;" class="app-tags-configurator">
	<span class="app-tags-configurator__label mat-body-strong">Tags</span>

	<button mat-icon-button aria-label="edit tags field"
					class="app-tags-configurator__icon">
		<mat-icon [svgIcon]="vo.isInEditMode ? DcIcons.Close : DcIcons.Edit"
							(click)="toggle()"></mat-icon>
	</button>

	<div *ngIf="!vo.isInEditMode" class="app-tags-configurator__tags" cdkDropListGroup>
		<div
			cdkDropList
			cdkDropListOrientation="horizontal"
			*ngFor="let tag of vo.assignedTags; let i = index"
			[cdkDropListData]="i">
			<div class="tag-box" cdkDrag
					 [cdkDragData]="i"
					 (cdkDragEntered)="dragEntered($event)">
				<mat-chip-list>
					<mat-chip>
						<div *cdkDragPlaceholder class="tag-box-placeholder">
							{{tag.label}}
						</div>
						<div matChipAvatar [ngStyle]="
						 {
							'background-color': tag.color
						}">
						</div>
						{{tag.label}}
						<mat-icon id="{{cmpId + '-menu-trigger'}}" matChipTrailingIcon
											[matMenuTriggerFor]="tagActions"
											[matMenuTriggerData]="{$implicit: tag, currentIdx: i}"
											[svgIcon]="DcIcons.MenuMoreOptionsHandle">
						</mat-icon>
					</mat-chip>
				</mat-chip-list>
			</div>
		</div>
	</div>

	<mat-form-field *ngIf="vo.isInEditMode" class="app-tags" [appearance]="Appearance">
		<mat-chip-list #chipList aria-label="tags selection"
									 class="tags-list">
			<mat-chip
				id="{{cmpId + '-chip-' + i}}"
				class="tag-box"
				color="accent"
				*ngFor="let tag of vo.assignedTags; let i=index"
				(removed)="remove(tag)"
				[ngStyle]="
			 {
				'color': 'black'
			}
		">
				<div matChipAvatar [ngStyle]="
			 {
				'background-color': tag.color
			}">
				</div>

				{{tag.label}}

				<mat-icon id="{{cmpId + '-menu-trigger'}}" matChipTrailingIcon [matMenuTriggerFor]="tagActions"
									[matMenuTriggerData]="{$implicit: tag, currentIdx: i}"
									[svgIcon]="DcIcons.MenuMoreOptionsHandle">
				</mat-icon>
			</mat-chip>
		</mat-chip-list>

		<input
			placeholder="new-tag"
			i18n-placeholder="@@tag.new.placeholder"
			#tagInput
			[maxlength]="LABEL_MAX_LEN"
			[disabled]="vo.assignedTags.length >= TAG_MAX_LEN"
			[formControl]="tagCtrl"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			[matChipInputAddOnBlur]="addOnBlur"
			(matChipInputTokenEnd)="add($event)"
		/>
		<mat-hint mat-color="error" *ngIf="vo.assignedTags.length >= TAG_MAX_LEN" i18n="@@tag.maxCount">max-tag
			-reached</mat-hint>
		<mat-hint mat-color="error" *ngIf="tagInput.value.length >= LABEL_MAX_LEN" i18n="@@tag.labelMaxLength">max-tag-label-reached</mat-hint>

		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
				<mat-option id="{{cmpId + '-opt-'+ i}}" *ngFor="let tag of vo.filteredTags;let i=index" [value]="tag">
					<div class="tag">
						<div class="tag__color-preview" [ngStyle]="
         {
          'background-color': tag.color
        }"></div><span class="tag__label-preview">{{tag.label}}</span>
					</div>
				</mat-option>
			</mat-autocomplete>
	</mat-form-field>

	<mat-menu #tagActions="matMenu">
		<ng-template matMenuContent let-tag let-idx="currentIdx">
			<button id="{{cmpId + '-edit-btn-'+ idx}}" (click)="editTag(tag, idx)" mat-menu-item>
				<mat-icon [svgIcon]="DcIcons.Edit"></mat-icon>
				<span i18n="@@tag.edit">trans:Edit: {{tag.label}}</span>
			</button>
			<button id="{{cmpId + '-delete-btn-'+ idx}}" class="app-tag__remove" mat-menu-item (click)="remove(tag)">
				<mat-icon [svgIcon]="DcIcons.Delete"></mat-icon>
				<span i18n="@@tag.delete">trans:delete</span>
			</button>
		</ng-template>
	</mat-menu>
</div>
