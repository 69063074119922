import { DoBootstrap, Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { AngularJSToken } from '@dc-common-core';
import { AppIcons } from '@dc-common-ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { AdministrationModule } from './administration/administration.module';
import { angularJSProvider } from './ajs-init.provider';
import { AjsInjectorService } from './ajs-injector.service';
import { AjsScopeService } from './ajs-scope.service';
import { AjsStateService } from './ajs-state.service';
import { CoreModule } from './core/core.module';
import { AppEffects, AppSelector, rootReducer } from './core/store';
import { CustomElementsModule } from './custom-elements/custom-elements.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { RequestersModule } from './requesters';
import {
	DcIconsInjectable,
	GdIconsInjectable,
	HdIconsInjectable,
} from './ui/ui.module';

@NgModule({
	imports: [
		AdministrationModule,
		BrowserModule,
		UpgradeModule,
		CoreModule,
		CustomElementsModule,
		MiscellaneousModule,
		RequestersModule,
		StoreModule.forRoot(rootReducer),
		EffectsModule.forRoot([AppEffects]),
		StoreDevtoolsModule.instrument({
			maxAge: 20,
			logOnly: environment.production,
		}),
	],
	providers: [
		angularJSProvider,
		AjsInjectorService,
		AjsScopeService,
		AjsStateService,
		AppSelector,
	],
	declarations: [],
})
export class AppModule implements DoBootstrap {
	public constructor(
		@Inject(AngularJSToken) angular: any,
		@Inject(DcIconsInjectable) private readonly dcIcons: AppIcons,
		@Inject(GdIconsInjectable) private readonly gdIcons: AppIcons,
		@Inject(HdIconsInjectable) private readonly hdIcons: AppIcons
	) {}

	public ngDoBootstrap(): void {
		console.warn('bootstrapping root module...');
		this.dcIcons.addIconsToRegistry();
		this.gdIcons.addIconsToRegistry();
		this.hdIcons.addIconsToRegistry();
	}
}
