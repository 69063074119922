<ng-container>
	<form
		class="app-export-partition-configuration"
		autocomplete="off"
		[formGroup]="form">
		<mat-form-field id="{{ cmpId + '-input' }}" [appearance]="Appearance">
			<mat-label i18n="@@export.partition.count">Partitions</mat-label>
			<input
				type="number"
				matInput
				placeholder="count"
				i18n-placeholder="@@export.partition.count.placeholder"
				[formControlName]="ExportPartitionFormControls.partitionCount" />
			<mat-error
				*ngIf="
					form
						.get(ExportPartitionFormControls.partitionCount)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@export.partition.count.error.required">
				Valeur obligatoire
			</mat-error>
			<mat-error
				*ngIf="
					form
						.get(ExportPartitionFormControls.partitionCount)
						?.hasError(ValidationErrorKeys.Min)
				"
				i18n="@@export.partition.count.error.minvalue">
				Valeur minimal non accepte
			</mat-error>
		</mat-form-field>
	</form>
</ng-container>
