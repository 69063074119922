<ng-container *ngIf="vo$ | async; let vo">
	<ng-container *ngIf="vo.isLoading">
		<mat-spinner class="example-margin"></mat-spinner>
	</ng-container>

	<ng-container *ngIf="!vo.isLoading">
		<form
			class="export-template-form"
			[formGroup]="exportTemplateForm.form">
			<app-form-card headerTitle="generalConfigTitle" i18n-headerTitle="@@export.form.generalParams">
				<div cardContent class="export-template-form__common-config">
					<mat-form-field id="{{cmpId + '-connector-type-select'}}"
													[appearance]="Appearance">
						<mat-label i18n="@@export.connectorType">type</mat-label>
						<mat-select
							disableOptionCentering
							[formControl]="exportTemplateForm.connector"
							[compareWith]="exportTemplateForm.compareConnectors">
							<mat-option
								id="{{ cmpId + '-connector-' + i}}"
								*ngFor="let mode of vo.connectors | toArray; let i=index"
								[value]="mode">
								{{ mode.label }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field
						id="{{cmpId + '-export-type-select'}}"
						[appearance]="Appearance"
						*ngIf="
						exportTemplateForm.form.get(ExportTemplateFormControls.ExportType)
					">
						<mat-label i18n="@@export.exportType">output</mat-label>
						<mat-select
							disableOptionCentering
							[formControl]="exportTemplateForm.exportType">
							<mat-option
								id="{{cmpId + '-export-'+ i}}"
								*ngFor="let format of outputFormats; let i=index"
								[value]="format.value">
								{{ format.label }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</app-form-card>

			<app-form-card *ngIf="vo.isConfigurable" headerTitle="specificConfig" i18n-headerTitle="@@export.form.specificConfig">
				<div cardContent
				[ngClass]="{
					'export-template-form__connector-config': vo.isConfigurable
				}">
				<ng-container
					*ngIf="
						exportTemplateForm.form.get(ExportTemplateFormControls.CsvConfig)
					">
					<app-export-csv-configuration
						[formControlName]="ExportTemplateFormControls.CsvConfig"
						[charsets]="vo.charsets"></app-export-csv-configuration>
				</ng-container>

				<ng-container
					*ngIf="
						exportTemplateForm.form.get(ExportTemplateFormControls.XmlConfig)
					">
					<app-export-xml-configuration
						[formControlName]="
							ExportTemplateFormControls.XmlConfig
						"></app-export-xml-configuration>
				</ng-container>

				<ng-container
					*ngIf="
						exportTemplateForm.form.get(ExportTemplateFormControls.ExcelConfig)
					">
					<app-export-excel-configuration
						[formControlName]="
							ExportTemplateFormControls.ExcelConfig
						"></app-export-excel-configuration>
				</ng-container>

				<ng-container
					*ngIf="
						exportTemplateForm.form.get(ExportTemplateFormControls.DbConfig)
					">
					<app-export-db-configuration
						[formControlName]="
							ExportTemplateFormControls.DbConfig
						"></app-export-db-configuration>
				</ng-container>
			</div>
			</app-form-card>

			<app-form-card headerTitle="outputParams" i18n-headerTitle="@@export.form.outputParams">
				<div cardContent
						 [ngClass]="{
						 'export-template-form__connector-config': !vo.isConfigurable,
						 'export-template-form__extra-config': vo.isConfigurable
						 }">
					<div>
						<mat-checkbox
							id="{{ cmpId }}-keep-original-chkbx"
							color="primary"
							[formControlName]="ExportTemplateFormControls.KeepOriginalLabels">
						<span i18n="@@export.template.keepOriginalLabels">
							should keep original label
						</span>
						</mat-checkbox>
					</div>

					<div *ngIf="
					exportTemplateForm.form.get(ExportTemplateFormControls.Compress)">
						<mat-checkbox
							id="{{ cmpId }}-compress-chkbx"
							color="primary"
							[formControlName]="ExportTemplateFormControls.Compress">
							<span i18n="@@export.template.compress">compress</span>
						</mat-checkbox>
					</div>

					<mat-form-field
						*ngIf="
						exportTemplateForm.form.get(ExportTemplateFormControls.Path)"
						class="export-template-form__path-form"
						[appearance]="Appearance">
						<mat-label i18n="@@export.path">Chemin</mat-label>
						<div
							class="export-template-form__path-selection">
							<input
								type="text"
								id="{{ cmpId }}-path-selection-input"
								matInput
								placeholder="path"
								i18n-placeholder="@@export.path.placeholder"
								[formControlName]="ExportTemplateFormControls.Path" />
							<mat-icon
								[ngClass]="{'export-template-form__path-selection--is-disabled': exportTemplateForm.form.get(ExportTemplateFormControls.Path)?.disabled}"
								matSuffix
								(click)="openPathExplorerModal()"
								[svgIcon]="GdIcons.Search"></mat-icon>
						</div>
					</mat-form-field>

					<app-export-write-mode-configuration
						*ngIf="
							exportTemplateForm.form.get(
								ExportTemplateFormControls.WriteModeConfig
							)
						"
						[formControlName]="
							ExportTemplateFormControls.WriteModeConfig
						"></app-export-write-mode-configuration>

					<app-export-partition-configuration
						*ngIf="
							exportTemplateForm.form.get(ExportTemplateFormControls.Partitioning)
						"
						[formControlName]="
							ExportTemplateFormControls.Partitioning
						"></app-export-partition-configuration>

					<app-export-file-configuration
						*ngIf="
							exportTemplateForm.form.get(ExportTemplateFormControls.FileConfig)
						"
						[formControlName]="ExportTemplateFormControls.FileConfig"
						(handleFilePatternChange)="handleFilePatternChange()">
					</app-export-file-configuration>

					<div class="export-template-form__user-defined-pattern-help">
						<app-nav-doc [navigateToUrl]="FunctionalDocumentationKeys.ExportTemplate__FileNamePatternPreview"></app-nav-doc>
					</div>

					<mat-form-field
						class="export-template-form__user-defined-pattern-result"
						*ngIf="exportTemplateForm.form.get(ExportTemplateFormControls.FileNamePatternPrediction)"
						[appearance]="Appearance">
						<mat-label i18n="@@export.fileName.strategy.userDefinedFileName.pattern">Pattern</mat-label>
						<input
							type="text"
							matInput
							readonly="true"
							placeholder="userDefinedFileName"
							i18n-placeholder="@@export.fileName.strategy.userDefinedFileName.pattern"
							id="{{ cmpId }} + '-filename-pattern-input"
							[matTooltip]="exportTemplateForm.form.get(ExportTemplateFormControls.FileNamePatternPrediction)?.value"
							[formControlName]="ExportTemplateFormControls.FileNamePatternPrediction" />
						<mat-error
							*ngIf="exportTemplateForm.form.get(ExportTemplateFormControls.FileNamePatternPrediction)?.hasError(ValidationErrorKeys.Pattern)"
							i18n="
					@@export.fileName.strategy.userDefinedLabel.new.error.pattern">
							forbidden-characters-present
						</mat-error>
					</mat-form-field>

					<div *ngIf="exportTemplateForm.form.get(ExportTemplateFormControls.FileNamePatternPrediction)"
					 class="export-template-form__user-defined-pattern-preview">
						<span i18n="@@export.fileName.strategy.userDefinedFileName.pattern">
							preview
						</span>
						<span [matTooltip]="exportTemplateForm.form.get(ExportTemplateFormControls.FileNamePatternPrediction)?.value" class="export-template-form__user-defined-pattern-preview__result">
							{{exportTemplateForm.form.get(ExportTemplateFormControls.FileNamePatternPrediction)?.value}}
						</span>
						<span *ngIf="exportTemplateForm.form.get(ExportTemplateFormControls.FileNamePatternPrediction)?.hasError(ValidationErrorKeys.Pattern)">
							{{exportTemplateForm.form.get(ExportTemplateFormControls.FileNamePatternPrediction)?.getError(ValidationErrorKeys.Pattern) | json }}
						</span>
					</div>
			</div>
			</app-form-card>
		</form>
	</ng-container>
</ng-container>
