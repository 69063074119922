import { DOCUMENT } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { combineAll, DcBaseComponent } from '@dc-common-core';
import { ModalData } from '@dc-common-ui';
import { Store } from '@ngrx/store';
import { Map } from 'immutable';
import {
	BehaviorSubject,
	debounceTime,
	distinctUntilChanged,
	filter,
	Observable,
	takeUntil,
	tap,
} from 'rxjs';

import { DcIcons } from '../../../ui/app-dc.icons';
import {
	closeExecuteExportUsingTemplateModal,
	executeExportUsingTemplate,
	ExportTemplateSelector,
	fetchSelectedExportTemplateConfig,
	getAllAvailableTemplates,
	initExportDatablockUsingExistingTemplate,
	searchAvailableExportTemplate,
} from '../../store';
import { ExportTemplateDetailsComponent } from '../export-template-details/export-template-details.component';
import { ExportTemplateDetailsEntity } from '../export-template-details/export-template-details.entity';
import { ExportTemplateListItemEntity } from '../export-template-list/export-template-list-item.entity';

enum DebouncedSearchFormControls {
	searchInputCtrl = 'search-input-form',
}

@Component({
	selector: 'app-export-datablock-modal',
	templateUrl: 'export-datablock-modal.component.html',
	styleUrls: ['./export-datablock-modal.component.scss'],
})
export class ExportDatablockModalComponent<
	T extends { datablockId: number }
> extends DcBaseComponent {
	protected readonly Icons = DcIcons;
	public DebouncedSearchFormControls = DebouncedSearchFormControls;
	public readonly Appearance: MatFormFieldAppearance = 'outline';

	public searchInputForm: FormGroup;

	private readonly isExportDetailsErroredSubject = new BehaviorSubject<boolean>(
		false
	);

	@ViewChild(ExportTemplateDetailsComponent)
	public exportTemplateDetailsCmp!: ExportTemplateDetailsComponent;

	public vo$: Observable<{
		isExportInProgress: boolean;
		isExportDisabled: boolean;
		templateDetails: ExportTemplateDetailsEntity;
		availableTemplates: Map<
			number,
			ExportTemplateListItemEntity<ExportTemplateDetailsEntity>
		>;
	}>;

	public constructor(
		@Inject(MAT_DIALOG_DATA) public readonly modalData: ModalData & T,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly store: Store,
		private readonly exportTemplateSelector: ExportTemplateSelector,
		private readonly formBuilder: FormBuilder
	) {
		super();
		this.cmpId = 'export-template-modal';
		this.store.dispatch(
			initExportDatablockUsingExistingTemplate({
				excludePublic: false,
				excludeInactive: true,
			})
		);

		this.searchInputForm = this.formBuilder.group({
			[DebouncedSearchFormControls.searchInputCtrl]: [''],
		});

		const searchCtrl = this.searchInputForm.get(
			DebouncedSearchFormControls.searchInputCtrl
		) as FormControl<string>;

		searchCtrl.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				distinctUntilChanged(),
				debounceTime(400),
				filter(
					(search) => search !== '' && search !== null && search !== undefined
				),
				tap((search) => {
					this.store.dispatch(
						searchAvailableExportTemplate({
							excludePublic: false,
							searchTerm: search,
						})
					);
				})
			)
			.subscribe();

		this.vo$ = combineAll({
			isExportInProgress: this.exportTemplateSelector.getIsExportInProgress$(),
			isExportDisabled: this.isExportDetailsErroredSubject,
			templateDetails: this.exportTemplateSelector.getExportTemplateConfig$(),
			availableTemplates: this.exportTemplateSelector.getExportTemplateList$(),
		});
	}

	public onExportDetailsError(isErrored: boolean): void {
		this.isExportDetailsErroredSubject.next(isErrored);
	}

	public resetSelection($event: boolean): void {
		if (!$event) {
			return;
		}
		this.searchInputForm.patchValue({
			[DebouncedSearchFormControls.searchInputCtrl]: '',
		});
	}

	public selectOption(
		val: ExportTemplateListItemEntity<ExportTemplateDetailsEntity>
	): void {
		this.store.dispatch(
			fetchSelectedExportTemplateConfig({
				exportId: val.item.id,
			})
		);
	}

	public executeExport(): void {
		const templateDetails = this.exportTemplateDetailsCmp.extractFormData();
		this.store.dispatch(
			executeExportUsingTemplate({
				exportTemplate: templateDetails,
			})
		);
	}

	public getAllAvailableTemplates(focusOn: string): void {
		const elem = `${this.cmpId}${focusOn}`;
		const elementById = this.document.getElementById(elem);
		if (elementById === null) {
			return;
		}
		elementById.focus();
		this.store.dispatch(getAllAvailableTemplates());
	}

	public close(): void {
		this.store.dispatch(closeExecuteExportUsingTemplateModal());
	}
}
