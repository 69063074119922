import { Component } from '@angular/core';
import { DcBaseComponent } from '@dc-common-core';
import { BehaviorSubject, delay, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'app-test-user',
	templateUrl: './test-user.component.html',
	styleUrls: ['./test-user.component.scss'],
	inputs: ['name'],
})
export class TestUserComponent extends DcBaseComponent {
	public name$ = new BehaviorSubject<string>('start value');
	public constructor() {
		super();

		this.toObservable<string>('name')
			.pipe(
				takeUntil(this.onDestroy$),
				delay(5000),
				tap((val) => this.name$.next(val))
			)
			.subscribe();
	}
}
