import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IAppRootState } from '../../core/store';
import { RequestersFeatureKey, RequestersState } from './requesters.state';

interface RequestersRootState extends IAppRootState {
	[RequestersFeatureKey]: RequestersState;
}

@Injectable()
export class RequestersSelector {
	public constructor(private readonly store: Store<RequestersRootState>) {}

	private get state(): Observable<RequestersState> {
		return this.store.pipe(select((store) => store[RequestersFeatureKey]));
	}
}
