import {
	IListAttrs,
	IListColumnConfig,
	IListOperations,
} from '@dc-common-core';
import { immerable, produce } from 'immer';
import { Map } from 'immutable';

import { FormulaUsageEntity } from './formula-usage.entity';

interface IFormulaUsageListEntity extends IListAttrs<FormulaUsageEntity> {
	totalCount: number;
	availableColumns: Map<string, IListColumnConfig<FormulaUsageEntity>>;
}

const entityDefaults: IFormulaUsageListEntity = {
	elements: Map(),
	availableColumns: Map(),
	totalCount: 0,
};

export class FormulaUsageListEntity
	implements
		IFormulaUsageListEntity,
		IListOperations<FormulaUsageListEntity, FormulaUsageEntity>
{
	private [immerable] = true;

	public elements = entityDefaults.elements;
	public availableColumns = entityDefaults.availableColumns;
	public totalCount = entityDefaults.totalCount;

	public static build(
		availableColumns: Array<IListColumnConfig<FormulaUsageEntity>>
	): FormulaUsageListEntity {
		const inst = new FormulaUsageListEntity();
		const cols = availableColumns.reduce((acc, curr) => {
			acc.push([curr.field, curr]);
			return acc;
		}, new Array<[string, IListColumnConfig<FormulaUsageEntity>]>());
		inst.availableColumns = Map(cols);
		return inst;
	}
	private constructor() {}

	public setElements(
		elems: Map<string, FormulaUsageEntity>
	): FormulaUsageListEntity {
		return produce(this, (draft: FormulaUsageListEntity) => {
			draft.elements = elems;
		});
	}

	public getAvailableColumns(): Map<
		string,
		IListColumnConfig<FormulaUsageEntity>
	> {
		return this.availableColumns;
	}
}
