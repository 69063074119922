import { Record } from 'immutable';

export const RequestersFeatureKey = 'requesters';

interface IRequestersState {
	test: string;
}

const defaults: IRequestersState = {
	test: 'test',
};

export class RequestersState extends Record<IRequestersState>(defaults) {
	public static key = RequestersFeatureKey;

	public setVal(val: string): RequestersState {
		return this.set('test', val);
	}
}
