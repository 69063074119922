import { castDraft, immerable, produce } from 'immer';

import { AppMetadataEntity } from '../../../ui/components/app-metadata/app-metadata.entity';
import { ExportTemplateDetailsEntity } from '../export-template-details/export-template-details.entity';

interface IExportTemplateListItemEntity<T extends ExportTemplateDetailsEntity> {
	item: T;
	metadata: AppMetadataEntity;
}

const entityDefaults: IExportTemplateListItemEntity<ExportTemplateDetailsEntity> =
	{
		item: ExportTemplateDetailsEntity.build({}),
		metadata: AppMetadataEntity.build({}),
	};

export class ExportTemplateListItemEntity<T extends ExportTemplateDetailsEntity>
	implements IExportTemplateListItemEntity<ExportTemplateDetailsEntity>
{
	private [immerable] = true;
	public item = entityDefaults.item;
	public metadata = entityDefaults.metadata;
	public constructor(
		item: ExportTemplateDetailsEntity,
		metadata: AppMetadataEntity
	) {
		this.item = item;
		this.metadata = metadata;
	}

	public setMetadata(
		metadata: AppMetadataEntity
	): ExportTemplateListItemEntity<T> {
		return produce(this, (draft) => {
			draft.metadata = castDraft(metadata);
		});
	}
}
