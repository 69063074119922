import { createAction, props } from '@ngrx/store';

import { OrphanProjectListEntity } from '../components/orphan-project-list/orphan-project-list.entity';

export enum OrphanProjectActions {
	InitOrphanProjectsList = '[BE][Orphan Project] fetch orphan projects and available admins',
	RefreshOrphanProjectsList = '[BE][Orphan Project] Refresh orphan projects',
	FetchOrphanProjectsSuccess = '[BE][Orphan Project] fetch orphan projects success',
	FetchOrphanProjectsFail = '[BE][Orphan Project] fetch orphan projects failed',
	SetOrphanProjectAdmin = '[BE][Orphan Project] set admin for an orphan project',
}

export const initOrphanProjectsList = createAction(
	OrphanProjectActions.InitOrphanProjectsList
);

export const refreshOrphanProjectsList = createAction(
	OrphanProjectActions.RefreshOrphanProjectsList
);

export const setOrphanProjectAdmin = createAction(
	OrphanProjectActions.SetOrphanProjectAdmin,
	props<{ id: number; admin: string }>()
);

export const fetchOrphanProjectsSuccess = createAction(
	OrphanProjectActions.FetchOrphanProjectsSuccess,
	props<{ orphanProjectList: OrphanProjectListEntity }>()
);

export const fetchOrphanProjectsFail = createAction(
	OrphanProjectActions.FetchOrphanProjectsFail,
	props<{ error: unknown }>()
);
