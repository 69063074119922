<ng-container>
	<form
		class="app-export-excel-configuration"
		autocomplete="off"
		[formGroup]="form">
		<mat-form-field
			id="{{ cmpId + 'cell-coordinates-input' }}"
			[appearance]="Appearance">
			<mat-label i18n="@@export.excel.cell">cell coordinates</mat-label>
			<input
				type="text"
				matInput
				placeholder="coordinates"
				i18n-placeholder="@@export.excel.cell.placeholder"
				[formControlName]="ExportExcelFormControl.CellCoordinates" />
			<mat-error
				*ngIf="
					form
						.get(ExportExcelFormControl.CellCoordinates)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@export.excel.cell.error.required">
				Valeur obligatoire
			</mat-error>
		</mat-form-field>

		<div>
			<mat-checkbox
				id="{{ cmpId + '-keepHeaders-chkbx' }}"
				color="primary"
				[formControlName]="ExportExcelFormControl.KeepHeaders">
				<span i18n="@@export.excel.keepHeaders">exporter les entêtes</span>
			</mat-checkbox>
		</div>
	</form>
</ng-container>
