import { Component, Inject } from '@angular/core';
import {
	combineAll,
	DcBaseComponent,
	DcCoreDocumentationBasePath,
	DOC_URL,
} from '@dc-common-core';
import { CoreIcons } from '@dc-common-ui';
import { map, merge, Observable, of } from 'rxjs';

import { FunctionalDocumentationKeys } from '../../../core/documentation/functional-doc-keys';
import { TechnicalDocumentationKeys } from '../../../core/documentation/technical-doc-keys';

export enum DocumentationType {
	Technical = 'technical',
	Functional = 'functional',
}

/**
 * 'docType' input has DocumentationType.Functional value by default,
 * to point to a technical documentation configure this component
 * usage with 'docType' input with relevant value from DocumentationType enum above
 */

@Component({
	selector: 'app-nav-doc',
	templateUrl: './nav-doc.component.html',
	styleUrls: ['./nav-doc.component.scss'],
	inputs: ['docType', 'navigateToUrl'],
})
export class AppNavDocComponent extends DcBaseComponent {
	public CoreIcons = CoreIcons;

	public vo$: Observable<{
		to: string;
	}>;

	public constructor(
		@Inject(DOC_URL)
		private readonly docBaseEntity: DcCoreDocumentationBasePath
	) {
		super();
		this.vo$ = combineAll({
			type: merge(
				of(DocumentationType.Functional),
				this.toObservable<DocumentationType>('docType')
			),
			to: this.toObservable<
				FunctionalDocumentationKeys | TechnicalDocumentationKeys
			>('navigateToUrl'),
		}).pipe(
			map((obj) => {
				if (obj.type === DocumentationType.Functional) {
					const $to = obj.to as FunctionalDocumentationKeys;
					const prefix = this.docBaseEntity.getFuncBasePath();
					const docUrlPresent = Object.values(
						FunctionalDocumentationKeys
					).includes($to);
					if (!docUrlPresent) {
						throw new Error(
							`'Functional Documentation key ${obj.to} not present`
						);
					}
					return {
						to: `${prefix}/${obj.to}`,
					};
				}
				const prefix = this.docBaseEntity.getTechBasePath();
				const $to = obj.to as TechnicalDocumentationKeys;
				const docUrlPresent = Object.values(
					TechnicalDocumentationKeys
				).includes($to);
				if (!docUrlPresent) {
					throw new Error(`'Technical Documentation key ${obj.to} not present`);
				}
				return {
					to: `${prefix}/${obj.to}`,
				};
			})
		);
	}
}
