import { Record } from 'immutable';

import { FormulaUsageListEntity } from '../components/formula-usage-list/formula-usage-list.entity';

export interface IFormulaState {
	loading: boolean;
	list: FormulaUsageListEntity;
	allUsageCount: number;
}

export const defaults: IFormulaState = {
	loading: false,
	list: FormulaUsageListEntity.build([]),
	allUsageCount: 0,
};

export const FormulaFeatureKey = 'formulas';

export class FormulaState extends Record<IFormulaState>(defaults) {
	public static key = FormulaFeatureKey;

	public setLoading(isLoading: boolean): FormulaState {
		return this.set('loading', isLoading);
	}

	public setFormulaUsages(usages: FormulaUsageListEntity): FormulaState {
		return this.set('list', usages);
	}

	public selAllUsageCount(allUsageCount: number): FormulaState {
		return this.set('allUsageCount', allUsageCount);
	}
}
