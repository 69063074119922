interface IOrphanProjectEntity {
	id: number;
	label: string;
}

const entityDefaults: IOrphanProjectEntity = {
	id: -1,
	label: '',
};

export class OrphanProjectEntity implements IOrphanProjectEntity {
	public id = entityDefaults.id;
	public label = entityDefaults.label;

	public static build(
		params: Partial<IOrphanProjectEntity> = {}
	): OrphanProjectEntity {
		const instance = new OrphanProjectEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.label = params.label ?? entityDefaults.label;
		return instance;
	}
	private constructor() {}
}
