import {createReducer, on} from '@ngrx/store';

import {fetchFormulaUsage, fetchFormulaUsageSuccess} from './formula.actions';
import {FormulaState} from './formula.state';

export const formulaReducer = createReducer<FormulaState>(
	new FormulaState(),
	on(fetchFormulaUsage, (state) => state.setLoading(true)),
	on(fetchFormulaUsageSuccess, (state, action) =>
		state.setLoading(false).setFormulaUsages(action.elements).selAllUsageCount(action.allUsageCount)
	)
);
