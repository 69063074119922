import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

export interface Temperature {
	day: string;
	value: number;
}

export const TEMP_MIN = 30;
export const TEMP_MAX = 55;

const temperatures: Array<Temperature> = [
	{
		day: '1',
		value: 37,
	},
	{
		day: '2',
		value: 38,
	},
	{
		day: '3',
		value: 37,
	},
	{
		day: '4',
		value: 49,
	},
	{
		day: '5',
		value: 43,
	},
	{
		day: '6',
		value: 43,
	},
	{
		day: '7',
		value: 53,
	},
	{
		day: '8',
		value: 54,
	},
	{
		day: '9',
		value: 64,
	},
	{
		day: '10',
		value: 64,
	},
	{
		day: '11',
		value: 69,
	},
	{
		day: '12',
		value: 52,
	},
	{
		day: '13',
		value: 55,
	},
	{
		day: '14',
		value: 58,
	},
	{
		day: '15',
		value: 56,
	},
	{
		day: '16',
		value: 50,
	},
	{
		day: '17',
		value: 58,
	},
	{
		day: '18',
		value: 54,
	},
	{
		day: '19',
		value: 42,
	},
	{
		day: '20',
		value: 50,
	},
	{
		day: '21',
		value: 53,
	},
	{
		day: '22',
		value: 53,
	},
	{
		day: '23',
		value: 55,
	},
	{
		day: '24',
		value: 58,
	},
	{
		day: '25',
		value: 56,
	},
	{
		day: '26',
		value: 56,
	},
	{
		day: '27',
		value: 55,
	},
	{
		day: '28',
		value: 56,
	},
	{
		day: '29',
		value: 55,
	},
	{
		day: '30',
		value: 55,
	},
	{
		day: '31',
		value: 54,
	},
];

@Injectable({
	providedIn: 'root',
})
export class DemoService {
	private readonly temperaturesSubject = new BehaviorSubject<
		Array<Temperature>
	>(temperatures);
	public temperature$ = this.temperaturesSubject.asObservable();

	public async generateRandomTemperature(): Promise<void> {
		const temp = Math.floor(TEMP_MIN - 10 + TEMP_MAX * Math.random());
		const randomDay = Math.floor(1 + 28 * Math.random());
		const currentTemps = await firstValueFrom(this.temperaturesSubject);
		const updatedTemps = currentTemps.reduce<Array<Temperature>>(
			(acc, curr) => {
				if (curr.day === randomDay.toString()) {
					acc.push({
						day: curr.day,
						value: temp,
					});
					return acc;
				}
				acc.push(curr);
				return acc;
			},
			[]
		);

		this.temperaturesSubject.next(updatedTemps);
	}
}
