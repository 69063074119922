<app-modal id="app-tag-edit">
	<button mat-icon-button modal-close-btn (click)="close()">
		<mat-icon
			id="{{cmpId + '-close'}}"
			[svgIcon]="Icons.Close"
			i18n-matTooltip="@@general.closeModal"
			matTooltip="Férmer la modale"></mat-icon>
	</button>

	<div modal-body>
		<form #tagForm="ngForm" (keyup.enter)="save()">
			<mat-form-field id="{{cmpId + '-label-form-field'}}"
											[appearance]="Appearance"
											class="tag__label-field">
				<mat-label i18n="@@tag.edit.label">
					label
				</mat-label>
				<input id="{{cmpId + '-label-input'}}" type="text"
							 name="label" #label="ngModel"
							 required
							 [maxlength]="LABEL_MAX_LEN"
							 matInput
							 placeholder="tagLabel"
							 i18n-placeholder="@@tag.edit.label.placeholder"
							 [ngModel]="tagLabel" (ngModelChange)="setNewLabel($event)"/>
			</mat-form-field>

			<ngx-colors ngx-colors-trigger
									class="tag__color-picker"
									attachTo="app-tag-edit"
									name="colorPicker" #colorPicker="ngModel"
									[format]="allowedFormats"
									[hideTextInput]="hideTextInput"
									[hideColorPicker]="hideColorPicker"
									[acceptLabel]="acceptLabel"
									[cancelLabel]="cancelLabel"
									[(ngModel)]="tagColor">
			</ngx-colors>

			<mat-form-field id="{{cmpId + '-color-form-field'}}"
											[appearance]="Appearance"
											class="tag__color-field">
				<mat-label i18n="@@tag.edit.color">
					trans:color
				</mat-label>
				<input id="{{cmpId + '-color-input'}}"
							 type="text"
							 name="color" #color="ngModel"
							 required
							 [pattern]="colorPattern"
							 matInput
							 placeholder="tagColor"
							 i18n-placeholder="@@tag.edit.color.placeholder"
							 [ngModel]="tagColor" (ngModelChange)="setNewColor($event)"/>
			</mat-form-field>
		</form>

		<div *ngIf="label.invalid && label.errors?.['required']"
				 class="alert alert-danger" i18n="@@general.form.errors.required">label-required</div>
		<div *ngIf="label.invalid && label.errors?.['maxLength']"
				 class="alert alert-danger" i18n="@@general.form.errors.maxLength">max-len</div>

		<div *ngIf="color.invalid && color.errors?.['required']"
				 class="alert alert-danger" i18n="@@general.form.errors.required">color-required</div>
		<div *ngIf="color.invalid && color.errors?.['pattern']"
				 class="alert alert-danger" i18n="@@general.form.errors.invalidColor.hex">invalid-color</div>
	</div>

	<div modal-footer>
		<button
			id="{{cmpId + '-cancel-btn'}}"
			(click)="close()"
			mat-raised-button>
			<span i18n="@@general.edit.cancel">cancel</span>
		</button>
		<button
			id="{{cmpId + '-save-btn'}}"
			type="submit"
			[disabled]="!tagForm.valid"
			(click)="save()"
			mat-raised-button
			color="primary">
			<span i18n="@@general.edit.save">save</span>
		</button>
	</div>
</app-modal>
