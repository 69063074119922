import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { forbiddenCharactersFn } from './forbidden-characters';

export const forbiddenCharacters =
	(forbiddenVals: string | Array<string>): ValidatorFn =>
	(control: AbstractControl): ValidationErrors | null => {
		const { value } = control;
		return forbiddenCharactersFn(value, forbiddenVals);
	};
