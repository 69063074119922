import { inject } from '@angular/core';
import { AbsBaseRequester } from '@dc-common-core';

import { ProjectStorage } from '../../core/project.storage';

export interface IPathVariable {
	[key: string]: number | string | boolean | undefined | null;
}

export interface IQueryParams {
	[key: string]:
		| number
		| string
		| boolean
		| Array<number | string | boolean>
		| null
		| undefined;
}
export abstract class DcBaseRequester<
	M,
	V = IPathVariable,
	Q = IQueryParams
> extends AbsBaseRequester<M, V, Q> {
	private readonly projectStorage: ProjectStorage;

	protected constructor() {
		super();
		this.projectStorage = inject(ProjectStorage);
	}

	protected assignBasePath(): string {
		this.basePathEntity.setBasePath('/service');
		return this.basePathEntity.getBasePath();
	}

	protected assignFunctionalDocUrl(): string {
		this.baseDocumentationEntity.setFuncBasePath('/doc/dc_funct');
		return this.baseDocumentationEntity.getFuncBasePath();
	}

	protected assignTechnicalDocUrl(): string {
		this.baseDocumentationEntity.setTechBasePath('/doc/dc_tech');
		return this.baseDocumentationEntity.getTechBasePath();
	}

	public get projectId(): number {
		const projectStorage = this.projectStorage.getValue();
		if (projectStorage === undefined) {
			throw new Error('Can not query available connectors without project id');
		}
		if (projectStorage.projectId === -1) {
			console.warn(
				'Project not set. Select a project before performing any action'
			);
			this.store.dispatch({
				type: 'redirect:home',
			});
		}
		return projectStorage.projectId;
	}

	protected abstract resource: string;
	protected abstract elementsListUrl: string;
	protected abstract singleElementUrl: string;
}
