import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
	Component,
	ElementRef,
	EventEmitter,
	Output,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { combineAll, isSame, DcBaseComponent } from '@dc-common-core';
import { AppModalService } from '@dc-common-ui';
import { randHex } from '@ngneat/falso';
import {
	BehaviorSubject,
	firstValueFrom,
	map,
	Observable,
	ReplaySubject,
	takeUntil,
	tap,
	withLatestFrom,
	combineLatest,
} from 'rxjs';

import { DcIcons } from '../../app-dc.icons';
import { AppTagEditComponent } from '../app-tag-edit/app-tag-edit.component';
import { AppTagItemEntity } from './app-tag-item.entity';

@Component({
	selector: 'app-tag-configurator',
	templateUrl: './app-tag-configurator.component.html',
	styleUrls: ['./app-tag-configurator.component.scss'],
	inputs: ['configuredTags', 'availableTags'],
})
export class AppTagConfiguratorComponent extends DcBaseComponent {
	public TAG_MAX_LEN = 10;
	public DcIcons = DcIcons;
	public Appearance: MatFormFieldAppearance = 'standard';
	public addOnBlur = false;
	public separatorKeysCodes: Array<number> = [ENTER, COMMA];
	public tagCtrl = new FormControl('');
	public LABEL_MAX_LEN = 50;

	@Output()
	public hasChangedValue = new EventEmitter<boolean>();

	public vo$: Observable<{
		assignedTags: Array<AppTagItemEntity>;
		filteredTags: Array<AppTagItemEntity>;
		isInEditMode: boolean;
	}>;

	private originalState: Array<AppTagItemEntity> = [];
	private allUnfilteredAvailableTags: Array<AppTagItemEntity> = [];

	private readonly assignedTagsSubject = new ReplaySubject<
		Array<AppTagItemEntity>
	>(1);
	private readonly filteredTagsSubject = new BehaviorSubject<
		Array<AppTagItemEntity>
	>([]);
	private readonly isInEditModeSubject = new BehaviorSubject<boolean>(false);

	private readonly preConfigured: Array<AppTagItemEntity> = [];

	@ViewChild('tagInput')
	private readonly tagInput!: ElementRef<HTMLInputElement>;
	private addThroughInputFieldTriggered = false;

	public constructor(private readonly modalService: AppModalService) {
		super();
		this.cmpId = 'tag';

		const available$ =
			this.toObservable<Array<AppTagItemEntity>>('availableTags');

		const configured$ =
			this.toObservable<Array<AppTagItemEntity>>('configuredTags');

		available$
			.pipe(
				takeUntil(this.onDestroy$),
				tap((val) => {
					this.allUnfilteredAvailableTags = val;
					this.filteredTagsSubject.next(val);
				})
			)
			.subscribe();

		configured$
			.pipe(
				takeUntil(this.onDestroy$),
				map((t) => t.concat(this.preConfigured)),
				tap((entry) => {
					this.originalState = [...entry];
					this.assignedTagsSubject.next(entry);
				})
			)
			.subscribe();

		combineLatest([available$, configured$])
			.pipe(
				takeUntil(this.onDestroy$),
				tap(([available, configured]) => {
					this.filteredTagsSubject.next(this.filterOut(available, configured));
				})
			)
			.subscribe();

		this.tagCtrl.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				withLatestFrom(this.assignedTagsSubject),
				tap(([val, assigned]) => {
					if (val === null || val === '') {
						const withoutAssigned = this.filterOut(
							this.allUnfilteredAvailableTags,
							assigned
						);
						this.filteredTagsSubject.next(withoutAssigned);
						return;
					}
					const match = this.allUnfilteredAvailableTags.filter((a) =>
						a.label.includes(val)
					);
					const filtered = this.filterOut(match, assigned);
					this.filteredTagsSubject.next(filtered);
				})
			)
			.subscribe();

		this.vo$ = combineAll({
			assignedTags: this.assignedTagsSubject,
			filteredTags: this.filteredTagsSubject,
			isInEditMode: this.isInEditModeSubject,
		});
	}

	public async toggle(): Promise<void> {
		const currentMode = await firstValueFrom(this.isInEditModeSubject);
		this.isInEditModeSubject.next(!currentMode);
	}

	public async add(event: MatChipInputEvent): Promise<void> {
		// Clear the input value
		event.chipInput?.clear();
		this.tagCtrl.setValue(null);

		let inView = await firstValueFrom(this.assignedTagsSubject);
		const value = (event.value || '').trim();

		if (value === '') {
			return;
		}
		this.startTimer(); // trigger timer to check interval between input value and selection with enter key
		const foundInAvailableIdx = this.allUnfilteredAvailableTags.findIndex(
			(f) => f.label === value
		);
		if (foundInAvailableIdx !== -1) {
			const existingTag = this.allUnfilteredAvailableTags[foundInAvailableIdx];
			inView = this.updateVisibleTags(
				inView,
				existingTag.label,
				existingTag.color
			);
		} else {
			// Add our tag
			inView = this.updateVisibleTags(inView, value, randHex());
		}
		this.assignedTagsSubject.next(inView);
		const isRestored = isSame(inView, this.originalState);
		this.hasChangedValue.emit(!isRestored);

		const withoutAssigned = this.filterOut(
			this.allUnfilteredAvailableTags,
			inView
		);
		this.filteredTagsSubject.next(withoutAssigned);
	}

	public async remove(tag: AppTagItemEntity): Promise<void> {
		const inView = await firstValueFrom(this.assignedTagsSubject);
		const index = inView.findIndex((t) => t.label === tag.label);

		if (index >= 0) {
			inView.splice(index, 1);
			this.assignedTagsSubject.next(inView);
		}
		const isRestored = isSame(inView, this.originalState);
		this.hasChangedValue.emit(!isRestored);

		const withoutAssigned = this.filterOut(
			this.allUnfilteredAvailableTags,
			inView
		);
		this.filteredTagsSubject.next(withoutAssigned);
	}

	public async selected(event: MatAutocompleteSelectedEvent): Promise<void> {
		// The method is triggered when selecting a tag from the dropdown by enter key of by mouse click
		// If number of tags > allowedMax the input field is disabled, it should not be possible to trigger it.

		this.tagInput.nativeElement.value = '';
		this.tagCtrl.setValue(null);

		let inView = await firstValueFrom(this.assignedTagsSubject);

		// add() and selected() method triggered within short time window
		if (this.addThroughInputFieldTriggered) {
			inView.pop();
		}

		const selected = event.option.value as AppTagItemEntity;
		inView = this.updateVisibleTags(inView, selected.label, selected.color);
		this.assignedTagsSubject.next(inView);
		const isRestored = isSame(inView, this.originalState);
		this.hasChangedValue.emit(!isRestored);

		const withoutAssigned = this.filterOut(
			this.allUnfilteredAvailableTags,
			inView
		);
		this.filteredTagsSubject.next(withoutAssigned);
	}

	public async getUsedTags(): Promise<Array<AppTagItemEntity>> {
		let inView = await firstValueFrom(this.assignedTagsSubject);
		inView = this.setTagPositions(inView);
		return inView;
	}

	public async editTag(tag: AppTagItemEntity, index: number): Promise<void> {
		const dialogRef = this.modalService.openPopup<{
			selected: AppTagItemEntity;
		}>(AppTagEditComponent, {
			selected: tag,
			title: $localize`:i18n=@@tag.edit.popup.header:`,
			subTitle: tag.label,
		});
		const output = await firstValueFrom(dialogRef.afterClosed());
		if (output === undefined) {
			return;
		}
		const isTagLabelChanged = output.label !== tag.label;
		const isTagColorChanged = output.color !== tag.color;
		if (!isTagLabelChanged && !isTagColorChanged) {
			return;
		}
		const modified = tag.setLabel(output.label).setColor(output.color);
		const inView = await firstValueFrom(this.assignedTagsSubject);
		inView[index] = modified;
		this.assignedTagsSubject.next(inView);
		this.hasChangedValue.emit(isTagLabelChanged || isTagColorChanged);
	}

	public async dragEntered(event: CdkDragEnter<number>): Promise<void> {
		const drag = event.item;
		const dropList = event.container;
		const dragIndex = drag.data;
		const dropIndex = dropList.data;

		const phContainer = dropList.element.nativeElement;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const phElement = phContainer.querySelector('.cdk-drag-placeholder');
		if (phElement === null) {
			return;
		}
		phContainer.removeChild(phElement);
		const { parentElement } = phContainer;

		if (parentElement === null) {
			return;
		}
		parentElement.insertBefore(phElement, phContainer);

		const inView = await firstValueFrom(this.assignedTagsSubject);
		moveItemInArray(inView, dragIndex, dropIndex);
		const isSameList = isSame(inView, this.originalState, 'position');
		this.hasChangedValue.emit(!isSameList);
		this.assignedTagsSubject.next(inView);
	}

	private startTimer(): void {
		this.addThroughInputFieldTriggered = true;
		setTimeout(() => {
			this.addThroughInputFieldTriggered = false;
		}, 100); // arbitrary wait time
	}

	private setTagPositions(
		unsorted: Array<AppTagItemEntity>
	): Array<AppTagItemEntity> {
		return unsorted.map((t, index) => t.setPosition(index));
	}

	private updateVisibleTags(
		tags: Array<AppTagItemEntity>,
		newTagLabel: string,
		newTagColor: string
	): Array<AppTagItemEntity> {
		const foundIdx = tags.findIndex((f) => f.label === newTagLabel);
		if (foundIdx === -1) {
			tags.push(
				AppTagItemEntity.build({
					label: newTagLabel,
					color: newTagColor,
					position: tags.length,
				})
			);
		}
		return tags;
	}

	private filterOut(
		tagsToFilter: Array<AppTagItemEntity>,
		tagsToExclude: Array<AppTagItemEntity>
	): Array<AppTagItemEntity> {
		return tagsToFilter.reduce<Array<AppTagItemEntity>>((acc, curr) => {
			const found = tagsToExclude.some((ex) => curr.isEqualTo(ex));
			if (!found) {
				acc.push(curr);
			}
			return acc;
		}, []);
	}
}
