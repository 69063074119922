import { FormControl, FormGroup, Validators } from '@angular/forms';

import { predictedFileNamePattern } from '../../../ui/form/validation-pattern';
import { ExportFileConfigurationComponent } from '../export-file-configuration/export-file-configuration.component';
import { ExportFileConfigurationEntity } from '../export-file-configuration/export-file-configuration.entity';
import { ExportPartitionConfigurationComponent } from '../export-partition-configuration/export-partition-configuration.component';
import { ExportPartitionConfigurationEntity } from '../export-partition-configuration/export-partition-configuration.entity';
import { ExportWriteModeConfigurationComponent } from '../export-write-mode-configuration/export-write-mode-configuration.component';
import {
	ConnectorMode,
	ExportConnectorEntity,
} from './export-connector.entity';
import {
	ExportFormat,
	ExportTemplateDetailsEntity,
} from './export-template-details.entity';
import {
	ExportTemplateDetailsForm,
	ExportTemplateFormControls,
} from './export-template-details.form';

export abstract class ExportConfigFormHelper {
	public generate(id: number, form: FormGroup): ExportTemplateDetailsEntity {
		const commonConfig = this.buildCommonConfig(id, form);
		return this.buildCompletePayload(commonConfig, form);
	}

	public populate(
		config: ExportTemplateDetailsEntity,
		form: ExportTemplateDetailsForm
	): void {
		const isDb =
			config.connector.type === ConnectorMode.SQL ||
			config.connector.type === ConnectorMode.NoSQL ||
			config.connector.type === ConnectorMode.Neo4j;

		if (!isDb) {
			this.commonPopulate(config, form);
		}
		this.populateWithConfig(config, form);
	}

	public update(
		connector: ExportConnectorEntity,
		exportType: ExportFormat,
		form: ExportTemplateDetailsForm
	): void {
		const isDb =
			connector.type === ConnectorMode.SQL ||
			connector.type === ConnectorMode.NoSQL ||
			connector.type === ConnectorMode.Neo4j;

		if (!isDb) {
			this.commonUpdates(form);
		}
		this.updateWithConfig(connector, exportType, form);
	}

	protected abstract buildCompletePayload(
		commonConfig: ExportTemplateDetailsEntity,
		form: FormGroup
	): ExportTemplateDetailsEntity;

	protected abstract populateWithConfig(
		entity: ExportTemplateDetailsEntity,
		form: ExportTemplateDetailsForm
	): void;

	protected abstract updateWithConfig(
		connector: ExportConnectorEntity,
		exportType: ExportFormat,
		form: ExportTemplateDetailsForm
	): void;

	private buildCommonConfig(
		id: number,
		form: FormGroup
	): ExportTemplateDetailsEntity {
		return ExportTemplateDetailsEntity.build({
			id,
			connector:
				form.controls[ExportTemplateFormControls.ConnectorMode].value ??
				undefined,
			outputType:
				form.controls[ExportTemplateFormControls.ExportType].value ?? undefined,
			keepOriginalLabels:
				form.controls[ExportTemplateFormControls.KeepOriginalLabels].value ??
				undefined,
			compress:
				form.controls[ExportTemplateFormControls.Compress]?.value ?? undefined,
			path: form.controls[ExportTemplateFormControls.Path]?.value ?? undefined,
			partition:
				form.controls[ExportTemplateFormControls.Partitioning] &&
				ExportPartitionConfigurationComponent.currentCmpEntity !== undefined
					? ExportPartitionConfigurationComponent.currentCmpEntity
					: undefined,
			fileNameConfig:
				form.controls[ExportTemplateFormControls.FileConfig] &&
				ExportFileConfigurationComponent.currentCmpEntity !== undefined
					? ExportFileConfigurationComponent.currentCmpEntity
					: undefined,
			writeMode:
				form.controls[ExportTemplateFormControls.WriteModeConfig] &&
				ExportWriteModeConfigurationComponent.currentCmpEntity !== undefined
					? ExportWriteModeConfigurationComponent.currentCmpEntity
					: undefined,
		});
	}

	private commonPopulate(
		config: ExportTemplateDetailsEntity,
		exportTemplateForm: ExportTemplateDetailsForm
	): void {
		exportTemplateForm.partition = new FormControl(
			ExportPartitionConfigurationEntity.build()
		);
		exportTemplateForm.form.addControl(
			ExportTemplateFormControls.Partitioning,
			exportTemplateForm.partition
		);

		exportTemplateForm.fileConfig = new FormControl(
			ExportFileConfigurationEntity.build()
		);
		exportTemplateForm.form.addControl(
			ExportTemplateFormControls.FileConfig,
			exportTemplateForm.fileConfig
		);

		exportTemplateForm.compress = new FormControl(false);
		exportTemplateForm.form.addControl(
			ExportTemplateFormControls.Compress,
			exportTemplateForm.compress
		);

		exportTemplateForm.fileNamePatternPrediction = new FormControl<string>(
			{
				value: config.fileNameConfig.predicted,
				disabled: false,
			},
			[Validators.pattern(predictedFileNamePattern)]
		);
		exportTemplateForm.form.addControl(
			ExportTemplateFormControls.FileNamePatternPrediction,
			exportTemplateForm.fileNamePatternPrediction
		);

		exportTemplateForm.toggleControls(
			true,
			ExportTemplateFormControls.ExportType,
			ExportTemplateFormControls.FileConfig,
			ExportTemplateFormControls.Partitioning,
			ExportTemplateFormControls.KeepOriginalLabels,
			ExportTemplateFormControls.Compress,
			ExportTemplateFormControls.FileNamePatternPrediction
		);
	}

	private commonUpdates(exportForm: ExportTemplateDetailsForm): void {
		exportForm.partition = new FormControl(
			ExportPartitionConfigurationEntity.build()
		);
		exportForm.form.addControl(
			ExportTemplateFormControls.Partitioning,
			exportForm.partition
		);

		exportForm.fileConfig = new FormControl(
			ExportFileConfigurationEntity.build()
		);
		exportForm.form.addControl(
			ExportTemplateFormControls.FileConfig,
			exportForm.fileConfig
		);

		exportForm.form.addControl(
			ExportTemplateFormControls.Path,
			new FormControl<string>('/')
		);

		exportForm.form.addControl(
			ExportTemplateFormControls.Compress,
			new FormControl<boolean>(false)
		);

		exportForm.form.addControl(
			ExportTemplateFormControls.FileNamePatternPrediction,
			new FormControl<string>('', [
				Validators.pattern(predictedFileNamePattern),
			])
		);

		exportForm.toggleControls(
			true,
			ExportTemplateFormControls.ExportType,
			ExportTemplateFormControls.FileConfig,
			ExportTemplateFormControls.Partitioning,
			ExportTemplateFormControls.Path,
			ExportTemplateFormControls.KeepOriginalLabels,
			ExportTemplateFormControls.Compress,
			ExportTemplateFormControls.FileNamePatternPrediction
		);
	}
}
