import { FormControl, FormGroup } from '@angular/forms';

import { ExportDbConfigurationComponent } from '../export-db-configuration/export-db-configuration.component';
import { ExportDbConfigurationEntity } from '../export-db-configuration/export-db-configuration.entity';
import { ExportWriteModeConfigurationEntity } from '../export-write-mode-configuration/export-write-mode-configuration.entity';
import { ExportConfigFormHelper } from './abstract-export-template-form-helper';
import { ExportConnectorEntity } from './export-connector.entity';
import {
	ExportFormat,
	ExportTemplateDetailsEntity,
} from './export-template-details.entity';
import {
	ExportTemplateDetailsForm,
	ExportTemplateFormControls,
} from './export-template-details.form';

export class DatabaseExportFormHelper extends ExportConfigFormHelper {
	protected buildCompletePayload(
		commonConfig: ExportTemplateDetailsEntity,
		form: FormGroup
	): ExportTemplateDetailsEntity {
		if (
			form.controls[ExportTemplateFormControls.DbConfig] !== null &&
			ExportDbConfigurationComponent.currentCmpEntity !== undefined
		) {
			return commonConfig.merge(
				ExportDbConfigurationComponent.currentCmpEntity
			);
		}
		return commonConfig;
	}

	protected populateWithConfig(
		entity: ExportTemplateDetailsEntity,
		exportForm: ExportTemplateDetailsForm
	): void {
		if (entity.outputType === ExportFormat.None) {
			exportForm.form.addControl(
				ExportTemplateFormControls.DbConfig,
				new FormControl(ExportDbConfigurationEntity.build())
			);
			exportForm.form.addControl(
				ExportTemplateFormControls.WriteModeConfig,
				new FormControl(ExportWriteModeConfigurationEntity.build())
			);
			exportForm.toggleControls(
				false,
				ExportTemplateFormControls.ExportType,
				ExportTemplateFormControls.Compress
			);
			exportForm.form.patchValue({
				[ExportTemplateFormControls.ConnectorMode]: entity.connector,
				[ExportTemplateFormControls.ExportType]: entity.outputType,
				[ExportTemplateFormControls.KeepOriginalLabels]:
					entity.keepOriginalLabels,
				[ExportTemplateFormControls.Compress]: entity.compress,
				[ExportTemplateFormControls.DbConfig]: entity.config,
				[ExportTemplateFormControls.WriteModeConfig]: entity.writeMode,
			});
			return;
		}
	}

	protected updateWithConfig(
		connector: ExportConnectorEntity,
		exportType: ExportFormat,
		exportForm: ExportTemplateDetailsForm
	): void {
		exportForm.form.addControl(
			ExportTemplateFormControls.DbConfig,
			new FormControl(ExportDbConfigurationEntity.build())
		);
		exportForm.form.addControl(
			ExportTemplateFormControls.WriteModeConfig,
			new FormControl(ExportWriteModeConfigurationEntity.build())
		);
		exportForm.form
			.get(ExportTemplateFormControls.ExportType)
			?.patchValue(ExportFormat.None, {
				emitEvent: false,
			});
		exportForm.toggleControls(false, ExportTemplateFormControls.ExportType);

		exportForm.removeControls(
			ExportTemplateFormControls.CsvConfig,
			ExportTemplateFormControls.XmlConfig,
			ExportTemplateFormControls.ExcelConfig,
			ExportTemplateFormControls.FileConfig,
			ExportTemplateFormControls.Partitioning,
			ExportTemplateFormControls.Path,
			ExportTemplateFormControls.FileNamePatternPrediction,
			ExportTemplateFormControls.Compress
		);
		return;
	}
}
