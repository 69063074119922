import { Component } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcBaseComponent } from '@dc-common-core';
import { AppModalService } from '@dc-common-ui';
import { DcIcons } from 'src/app/ui/app-dc.icons';

@Component({
	selector: 'app-export-template-delete-confirmation',
	templateUrl: './export-template-delete-confirmation.component.html',
	styleUrls: ['./export-template-delete-confirmation.component.scss'],
})
export class ExportTemplateDeleteConfirmationComponent extends DcBaseComponent {
	protected readonly Icons = DcIcons;
	public readonly Appearance: MatFormFieldAppearance = 'outline';

	public constructor(private readonly modalService: AppModalService) {
		super();
		this.cmpId = 'delete-export-template-modal';
	}

	public confirmDelete(): void {
		this.modalService.closeActiveModal({
			action: true,
		});
	}

	public cancelDelete(): void {
		this.modalService.closeActiveModal({
			action: false,
		});
	}
}
