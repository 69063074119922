import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

const modules = [
	DragDropModule,
	MatChipsModule,
	MatIconModule,
	MatTooltipModule,
	MatProgressSpinnerModule,
	MatFormFieldModule,
	MatSelectModule,
	MatInputModule,
	MatCheckboxModule,
	MatButtonModule,
	MatRadioModule,
	MatSlideToggleModule,
	MatDialogModule,
	MatAutocompleteModule,
	MatDividerModule,
	MatMenuModule,
];

@NgModule({
	imports: [],
	exports: [...modules],
})
export class MaterialModule {}
