import { immerable } from 'immer';

export type FormulaUsageDetails = Array<{ url: string; label: string }>;

// TODO: extract id attrobute into interface

interface IFormulaUsageEntity {
	id: number;
	datablock: FormulaUsageDetails;
	hdSource: FormulaUsageDetails;
	entityCharacteristic: FormulaUsageDetails;
	entityLink: FormulaUsageDetails;
	label: string;
	category: string;
	count: number;
	isDeprecated: boolean;
}

const entityDefaults: IFormulaUsageEntity = {
	id: -1,
	datablock: [],
	hdSource: [],
	entityCharacteristic: [],
	entityLink: [],
	label: '',
	category: '',
	count: 0,
	isDeprecated: false,
};

export class FormulaUsageEntity implements IFormulaUsageEntity {
	private [immerable] = true;

	public id = -1;
	public label = entityDefaults.label;
	public category = entityDefaults.category;
	public isDeprecated = entityDefaults.isDeprecated;
	public count = entityDefaults.count;
	public datablock = entityDefaults.datablock;
	public hdSource = entityDefaults.hdSource;
	public entityCharacteristic = entityDefaults.entityCharacteristic;
	public entityLink = entityDefaults.entityLink;

	public static build(params: IFormulaUsageEntity): FormulaUsageEntity {
		const instance = new FormulaUsageEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.label = params.label ?? entityDefaults.label;
		instance.category = params.category ?? entityDefaults.category;
		instance.isDeprecated = params.isDeprecated ?? entityDefaults.isDeprecated;
		instance.count = params.count ?? entityDefaults.count;
		instance.datablock = params.datablock ?? entityDefaults.datablock;
		instance.hdSource = params.hdSource ?? entityDefaults.hdSource;
		instance.entityCharacteristic =
			params.entityCharacteristic ?? entityDefaults.entityCharacteristic;
		instance.entityLink = params.entityLink ?? entityDefaults.entityLink;
		return instance;
	}
}
