<ng-container>
	<form
		class="app-export-write-mode-configuration"
		autocomplete="off"
		[formGroup]="form">
		<mat-form-field
			id="{{ cmpId + 'write-mode-select' }}"
			[appearance]="Appearance">
			<mat-label i18n="@@export.writeMode">Write mode</mat-label>
			<mat-select disableOptionCentering
									[formControlName]="ExportWriteModeFormControls.Mode">
				<mat-option
					id="{{ cmpId + 'write-mode-opt' + i }}"
					*ngFor="let opt of writeOptions; let i = index"
					[value]="opt.value">
					{{ opt.label }}
				</mat-option>
			</mat-select>
			<mat-error
				*ngIf="
					form
						.get(ExportWriteModeFormControls.Mode)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@export.writeMode.error.required">
				Valeur obligatoire
			</mat-error>
		</mat-form-field>
	</form>
</ng-container>
