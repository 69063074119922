import { Injectable } from '@angular/core';

import { IProjectMemberModel } from '../requesters/core/project-member.model';
import { IProjectModel } from '../requesters/core/project.model';
import { OrphanProjectEntity } from './components/orphan-project-list/orphan-project.entity';
import { ProjectAdminEntity } from './components/orphan-project-list/project-admin.entity';

@Injectable()
export class OrphanProjectParser {
	public parseOrphanProjects(
		response: ReadonlyArray<IProjectModel>
	): Array<OrphanProjectEntity> {
		return response.map((project) =>
			OrphanProjectEntity.build({
				id: project.id,
				label: project.metadata?.label ?? '',
			})
		);
	}

	public parseAdmins(
		response: ReadonlyArray<IProjectMemberModel>
	): Array<ProjectAdminEntity> {
		return response
			.filter(
				(user) =>
					(user.enabled || user.is_group) &&
					user.has_admin_role &&
					user.has_project_role
			)
			.map((user) =>
				ProjectAdminEntity.build({
					id: user.id,
					name: user.name,
				})
			);
	}
}
