import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';

import { DemoComponent } from '../ui/components/demo/demo.component';

@NgModule({
	declarations: [],
	imports: [CommonModule],
})
export class CustomElementsModule {
	public constructor(private readonly injector: Injector) {
		const demoCmpElement = createCustomElement(DemoComponent, {
			injector,
		});
		customElements.define('app-test-cmp', demoCmpElement);
	}
}
