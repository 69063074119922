import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcValueAccessorComponent } from '@dc-common-core';
import { takeUntil, tap } from 'rxjs';

import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import { ExportPartitionConfigurationEntity } from './export-partition-configuration.entity';

enum ExportPartitionFormControls {
	partitionCount = 'count',
}

@Component({
	selector: 'app-export-partition-configuration',
	templateUrl: './export-partition-configuration.component.html',
	styleUrls: ['./export-partition-configuration.component.scss'],
	providers: [
		DcValueAccessorComponent.generateAccessorToken(
			ExportPartitionConfigurationComponent
		),
		DcValueAccessorComponent.generateValidatorToken(
			ExportPartitionConfigurationComponent
		),
	],
})
export class ExportPartitionConfigurationComponent extends DcValueAccessorComponent<
	ExportPartitionConfigurationComponent,
	ExportPartitionConfigurationEntity
> {
	public Appearance: MatFormFieldAppearance = 'outline';
	public ExportPartitionFormControls = ExportPartitionFormControls;
	public ValidationErrorKeys = ValidationErrorKeys;

	public static currentCmpEntity:
		| ExportPartitionConfigurationEntity
		| undefined = undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private static toEntity(formValues: any): ExportPartitionConfigurationEntity {
		return ExportPartitionConfigurationEntity.build({
			count: formValues[ExportPartitionFormControls.partitionCount],
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static saveCurrent(value: any): void {
		this.currentCmpEntity =
			ExportPartitionConfigurationComponent.toEntity(value);
	}

	public constructor(private readonly fb: FormBuilder) {
		super();
		this.cmpId = 'export-partitionCount-config';

		this.form = this.fb.group({
			[ExportPartitionFormControls.partitionCount]: [
				1,
				[Validators.required, Validators.min(1)],
			],
		});

		this.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap((val) => {
					this.onChange(val);
					ExportPartitionConfigurationComponent.saveCurrent(val);
					Object.keys(this.form.controls).forEach((k) => {
						const ctrl = this.form.get(k) as AbstractControl;
						ctrl.markAsTouched();
					});
				})
			)
			.subscribe();
	}

	public extract(): ExportPartitionConfigurationEntity {
		const formValues = this.form.value;
		return ExportPartitionConfigurationComponent.toEntity(formValues);
	}

	protected writeIntoForm(obj: ExportPartitionConfigurationEntity): void {
		this.form.patchValue({
			[ExportPartitionFormControls.partitionCount]: obj.count,
		});
		ExportPartitionConfigurationComponent.saveCurrent(this.form.value);
	}
}
