import { NgModule } from '@angular/core';
import { DxButtonModule, DxChartModule } from 'devextreme-angular';

const modules = [DxButtonModule, DxChartModule];

@NgModule({
	imports: [],
	exports: [...modules],
})
export class DxtremeModule {}
