import { FormControl, FormGroup, Validators } from '@angular/forms';

import { predictedFileNamePattern } from '../../../ui/form/validation-pattern';
import { ExportCsvConfigurationComponent } from '../export-csv-configuration/export-csv-configuration.component';
import { ExportCsvConfigurationEntity } from '../export-csv-configuration/export-csv-configuration.entity';
import { ExportConfigFormHelper } from './abstract-export-template-form-helper';
import {
	ConnectorMode,
	ExportConnectorEntity,
} from './export-connector.entity';
import {
	ExportFormat,
	ExportTemplateDetailsEntity,
} from './export-template-details.entity';
import {
	ExportTemplateDetailsForm,
	ExportTemplateFormControls,
} from './export-template-details.form';

export class CsvExportFormHelper extends ExportConfigFormHelper {
	protected buildCompletePayload(
		commonConfig: ExportTemplateDetailsEntity,
		form: FormGroup
	): ExportTemplateDetailsEntity {
		if (
			form.controls[ExportTemplateFormControls.CsvConfig] !== null &&
			ExportCsvConfigurationComponent.currentCmpEntity !== undefined
		) {
			return commonConfig.merge(
				ExportCsvConfigurationComponent.currentCmpEntity
			);
		}
		return commonConfig;
	}

	protected populateWithConfig(
		entity: ExportTemplateDetailsEntity,
		exportForm: ExportTemplateDetailsForm
	): void {
		exportForm.form.addControl(
			ExportTemplateFormControls.Path,
			new FormControl('/', [Validators.required])
		);
		exportForm.form.addControl(
			ExportTemplateFormControls.CsvConfig,
			new FormControl(ExportCsvConfigurationEntity.build())
		);
		exportForm.form.patchValue({
			[ExportTemplateFormControls.ConnectorMode]: entity.connector,
			[ExportTemplateFormControls.ExportType]: entity.outputType,
			[ExportTemplateFormControls.KeepOriginalLabels]:
				entity.keepOriginalLabels,
			[ExportTemplateFormControls.Compress]: entity.compress,
			[ExportTemplateFormControls.Path]: entity.path,
			[ExportTemplateFormControls.CsvConfig]: entity.config,
			[ExportTemplateFormControls.Partitioning]: entity.partition,
			[ExportTemplateFormControls.FileConfig]: entity.fileNameConfig,
		});
		exportForm.toggleControls(
			entity.connector.type !== ConnectorMode.Local,
			ExportTemplateFormControls.Path
		);
		return;
	}

	protected updateWithConfig(
		connector: ExportConnectorEntity,
		exportType: ExportFormat,
		exportForm: ExportTemplateDetailsForm
	): void {
		exportForm.removeControls(
			ExportTemplateFormControls.WriteModeConfig,
			ExportTemplateFormControls.XmlConfig,
			ExportTemplateFormControls.ExcelConfig,
			ExportTemplateFormControls.DbConfig
		);
		exportForm.form.addControl(
			ExportTemplateFormControls.Path,
			new FormControl('/', [Validators.required])
		);
		exportForm.form.addControl(
			ExportTemplateFormControls.FileNamePatternPrediction,
			new FormControl('', [Validators.pattern(predictedFileNamePattern)])
		);
		exportForm.form.addControl(
			ExportTemplateFormControls.CsvConfig,
			new FormControl(ExportCsvConfigurationEntity.build())
		);

		if (connector.type === ConnectorMode.Local) {
			exportForm.form.patchValue({
				[ExportTemplateFormControls.Path]: '/',
			});
			exportForm.exportTemplateDetailsHelper.setPathValueDisabledAndChanged(
				true
			);
		} else {
			exportForm.exportTemplateDetailsHelper.setPathValueDisabledAndChanged(
				false
			);
		}

		exportForm.toggleControls(
			connector.type !== ConnectorMode.Local,
			ExportTemplateFormControls.Path
		);
		return;
	}
}
