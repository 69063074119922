import { Record } from 'immutable';

import { OrphanProjectListEntity } from '../components/orphan-project-list/orphan-project-list.entity';

export interface IProjectState {
	loading: boolean;
	orphanProjectList: OrphanProjectListEntity;
}

export const defaults: IProjectState = {
	loading: false,
	orphanProjectList: OrphanProjectListEntity.build(),
};

export const OrphanProjectFeatureKey = 'orphan-project';

export class OrphanProjectState extends Record<IProjectState>(defaults) {
	public static key = OrphanProjectFeatureKey;

	public setLoading(isLoading: boolean): OrphanProjectState {
		return this.set('loading', isLoading);
	}

	public setOrphanProjectList(
		orphanProjectList: OrphanProjectListEntity
	): OrphanProjectState {
		return this.set('orphanProjectList', orphanProjectList);
	}
}
