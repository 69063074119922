import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { loadTranslations } from '@angular/localize';
import { SupportedLocales, DcBaseInitializationService } from '@dc-common-core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Datachain } from './app/core/constants';
import { loadMessages, locale } from 'devextreme/localization';
import frMessages from 'devextreme/localization/messages/fr.json';

async function start(): Promise<void> {
	await fetchConfigurations();
	await fetchTranslations();
}

async function fetchConfigurations(): Promise<void> {
	return new Promise((resolve, reject) => {
		fetch('/service/private/apiPaths')
			.then((response) => response.json())
			.then((paths) => {
				const config = DcBaseInitializationService.getConfigInstance();
				DcBaseInitializationService.updateConfig(
					config.setAvailableResources(paths)
				);
				setTimeout(resolve, 100);
			});
	});
}

async function fetchTranslations(): Promise<void> {
	const usedLangLocale = getLocale();
	const config = DcBaseInitializationService.getConfigInstance();
	DcBaseInitializationService.updateConfig(config.setLangLocal(usedLangLocale));

	if (usedLangLocale === SupportedLocales.Fr) {
		loadMessages(frMessages);
		locale(usedLangLocale);
	}

	fetch(`${environment.localePath}/messages.${usedLangLocale}.json`)
		.then((response) => {
			return response.json();
		})
		.then((translationJson) => {
			const translation = translationJson['translations'];
			if (translation) {
				loadTranslations(translation);
			}
		})
		.catch((err) => {
			console.error(err);
		});
}

function getLocale(): string {
	const saved = localStorage.getItem(Datachain.CachedLang);
	return saved === null || saved === undefined
		? SupportedLocales.Fr
		: parseLang(saved);
}

function parseLang(langKey: string): string {
	return langKey === 'fr' ? SupportedLocales.Fr : SupportedLocales.En;
}

function runApp(): void {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((err) => console.error(err));
}

start().then(runApp, (err) => console.error(err));
