import { ValidationErrorKeys } from './validation-erros-keys';

export const forbiddenCharactersFn = (
	value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	forbiddenChars: string | Array<string>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): { [k: string]: any } | null => {
	let forbidden = [];
	if (!Array.isArray(forbiddenChars)) {
		forbidden = Array.from(forbiddenChars);
	} else {
		forbidden = forbiddenChars;
	}

	let hasForbiddenChar = false;
	// eslint-disable-next-line @typescript-eslint/prefer-for-of
	for (let i = 0; i < forbidden.length; i++) {
		hasForbiddenChar = (value as string).includes(forbidden[i]);
		if (hasForbiddenChar) {
			break;
		}
	}

	if (hasForbiddenChar) {
		return {
			[ValidationErrorKeys.ForbiddenCharacters]: true,
		};
	}
	return null;
};
