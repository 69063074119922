import { Injectable } from '@angular/core';
import { AppIcons, IIcons } from '@dc-common-ui';

export enum DcIcons {
	Common = 'Common',
	Close = 'close',
	Delete = 'delete',
	Edit = 'edit',
	Formula = 'formula',
	MenuMoreOptionsHandle = 'menu-more-options-open-handle',
	StackedFormula = 'stacked-formula',
}

@Injectable()
export class AppDcIcons extends AppIcons {
	private readonly path = './src/img';

	protected icons: IIcons = {
		[DcIcons.Common]: `${this.path}/genericsData-color-solo.svg`,
		[DcIcons.Close]: `${this.path}/common/close_black_24dp.svg`,
		[DcIcons.Delete]: `${this.path}/common/delete_FILL0_wght400_GRAD0_opsz24.svg`,
		[DcIcons.Edit]: `${this.path}/common/edit_FILL0_wght400_GRAD0_opsz24.svg`,
		[DcIcons.Formula]: `${this.path}/svg/icon-formula.svg`,
		[DcIcons.StackedFormula]: `${this.path}/common/dc-formula.svg`,
		[DcIcons.MenuMoreOptionsHandle]: `${this.path}/common/more_vert_FILL0_wght400_GRAD0_opsz24.svg`,
	};
}
