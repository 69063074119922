import { Injectable } from '@angular/core';
import { UrlService } from '@dc-common-core';
import { Observable } from 'rxjs';

import { DcBaseRequester } from '../core/dc-base.requester';

@Injectable({
	providedIn: 'root',
})
export class FilenameLanguageTranslationRequester extends DcBaseRequester<
	ReadonlyArray<string>
> {
	protected elementsListUrl: string;
	protected resource: string;
	protected singleElementUrl: string;

	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('SMALL_LANGUAGE_TRANSLATE');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public predictOutputFileName(
		pathsToDeduce: Array<string>,
		datablockId: number | undefined = undefined
	): Observable<ReadonlyArray<string>> {
		const url = this.urlService.generateUrl(
			this.elementsListUrl,
			{},
			{
				project: this.projectId,
				datablock: datablockId,
			}
		);
		return this.http.post<ReadonlyArray<string>>(url, pathsToDeduce);
	}
}
