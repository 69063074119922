import { Component, EventEmitter, Output } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	Validators,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcValueAccessorComponent } from '@dc-common-core';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	Subject,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs';

import { forbiddenCharacters } from '../../../ui/form/forbidden-characters.validator';
import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import {
	ExportFileConfigurationEntity,
	FileNameGenStrategy,
} from './export-file-configuration.entity';

interface IFileNamingOptions {
	label: string;
	value: string;
	disabled?: boolean;
}

enum ExportFileFormControls {
	Strategy = 'strategy',
	FileName = 'fileName',
}

@Component({
	selector: 'app-export-file-configuration',
	templateUrl: './export-file-configuration.component.html',
	styleUrls: ['./export-file-configuration.component.scss'],
	providers: [
		DcValueAccessorComponent.generateAccessorToken(
			ExportFileConfigurationComponent
		),
		DcValueAccessorComponent.generateValidatorToken(
			ExportFileConfigurationComponent
		),
	],
})
export class ExportFileConfigurationComponent extends DcValueAccessorComponent<
	ExportFileConfigurationComponent,
	ExportFileConfigurationEntity
> {
	private readonly listenOnPatternChangeSubject = new Subject<boolean>();
	public Appearance: MatFormFieldAppearance = 'outline';
	public ExportFileFormControls = ExportFileFormControls;
	public FileNameGenStrategy = FileNameGenStrategy;
	public fileNamingOptions: Array<IFileNamingOptions> = [
		{
			label: $localize`:i18n=@@export.fileName.strategy.uuid:`,
			value: FileNameGenStrategy.UUID,
		},
		{
			label: $localize`:i18n=@@export.fileName.strategy.userDefinedFileName:`,
			value: FileNameGenStrategy.UserDefinedFileNamePattern,
		},
	];

	@Output()
	public handleFilePatternChange = new EventEmitter<string>();
	protected readonly ValidationErrorKeys = ValidationErrorKeys;
	public static currentCmpEntity: ExportFileConfigurationEntity | undefined =
		undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private static toEntity(formValues: any): ExportFileConfigurationEntity {
		return ExportFileConfigurationEntity.build({
			strategy: formValues[ExportFileFormControls.Strategy],
			userDefinedLabel: formValues[ExportFileFormControls.FileName],
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static saveCurrent(value: any): void {
		this.currentCmpEntity = ExportFileConfigurationComponent.toEntity(value);
	}

	public constructor(private readonly fb: FormBuilder) {
		super();
		this.cmpId = 'export-file-config';

		this.form = this.fb.group({
			[ExportFileFormControls.Strategy]: [
				FileNameGenStrategy.UUID,
				Validators.required,
			],
		});

		this.form
			.get(ExportFileFormControls.Strategy)
			?.valueChanges.pipe(
				takeUntil(this.onDestroy$),
				tap((val: FileNameGenStrategy) => {
					if (val === FileNameGenStrategy.UserDefinedFileNamePattern) {
						this.form.addControl(
							ExportFileFormControls.FileName,
							new FormControl('', [
								Validators.required,
								forbiddenCharacters(['/']),
							])
						);
						this.listenOnPatternChangeSubject.next(true);
						return;
					}
					this.form.removeControl(ExportFileFormControls.FileName);
					this.listenOnPatternChangeSubject.next(false);
					this.handleFilePatternChange.emit(val);
				})
			)
			.subscribe();

		this.listenOnPatternChangeSubject
			.pipe(
				filter((shouldListen) => shouldListen),
				switchMap(
					() =>
						(this.form.get(ExportFileFormControls.FileName) as FormControl)
							?.valueChanges
				),
				takeUntil(this.onDestroy$),
				debounceTime(750),
				distinctUntilChanged(),
				tap((val) => this.handleFilePatternChange.emit(val))
			)
			.subscribe();

		this.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap((val) => {
					this.onChange(val);
					ExportFileConfigurationComponent.saveCurrent(val);
					Object.keys(this.form.controls).forEach((k) => {
						const ctrl = this.form.get(k) as AbstractControl;
						ctrl.markAsTouched();
					});
				})
			)
			.subscribe();
	}

	public extract(): ExportFileConfigurationEntity {
		const formValues = this.form.value;
		return ExportFileConfigurationComponent.toEntity(formValues);
	}

	protected writeIntoForm(obj: ExportFileConfigurationEntity): void {
		if (obj.strategy === FileNameGenStrategy.UserDefinedFileNamePattern) {
			this.form.patchValue({
				[ExportFileFormControls.Strategy]: obj.strategy,
				[ExportFileFormControls.FileName]: obj.userDefinedLabel,
			});
			ExportFileConfigurationComponent.saveCurrent(this.form.value);
			return;
		}
		this.form.patchValue({
			[ExportFileFormControls.Strategy]: obj.strategy,
		});
		ExportFileConfigurationComponent.saveCurrent(this.form.value);
	}
}
