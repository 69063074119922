import { createReducer, on } from '@ngrx/store';

import { AppMetadataEntity } from '../../ui/components/app-metadata/app-metadata.entity';
import { ExportCsvConfigurationEntity } from '../components/export-csv-configuration/export-csv-configuration.entity';
import { ExportConnectorEntity } from '../components/export-template-details/export-connector.entity';
import {
	ExportFormat,
	ExportTemplateDetailsEntity,
} from '../components/export-template-details/export-template-details.entity';
import {
	closeExecuteExportUsingTemplateModal,
	configureSelectedDatablockExport,
	executeExportUsingTemplate,
	exportExecutionFinished,
	fetchAvailableCharsetsSuccess,
	fetchAvailableConnectorsSuccess,
	fetchAvailableTagsSuccess,
	fetchExportConfigSuccess,
	fetchExportTemplateConfig,
	fetchExportTemplateListSuccess,
	initExportTemplateConfig,
	predictExportFileNameBasedOnPattern,
	predictExportFileNameBasedOnPatternSuccess,
	resetExportTemplate,
} from './export-template.actions';
import { ExportTemplateState } from './export-template.state';

export const exportTemplateReducer = createReducer<ExportTemplateState>(
	new ExportTemplateState(),
	on(initExportTemplateConfig, resetExportTemplate, (state) => state.reset()),
	on(configureSelectedDatablockExport, (state) =>
		state
			.setExportTemplateConfig(
				ExportTemplateDetailsEntity.build({
					connector: ExportConnectorEntity.localConnector(),
					outputType: ExportFormat.CSV,
					config: ExportCsvConfigurationEntity.build(),
				})
			)
			.setExportMetadata(AppMetadataEntity.build({}))
			.setEmptyList()
			.setHasDirtyFileNamePattern(false)
	),
	on(configureSelectedDatablockExport, (state, action) =>
		state
			.setDatablockLabel(action.datablockLabel)
			.setDatablockId(action.datablockId)
	),
	on(fetchExportTemplateConfig, (state) =>
		state.setLoading(true).setDatablockId(undefined)
	),
	on(fetchExportConfigSuccess, (state, payload) =>
		state
			.setLoading(false)
			.setExportTemplateConfig(payload.entity)
			.setExportMetadata(payload.metadata)
	),
	on(fetchAvailableConnectorsSuccess, (state, payload) =>
		state.setAvailableConnectors(payload.connectors)
	),
	on(fetchAvailableCharsetsSuccess, (state, payload) =>
		state
			.setAvailableCharsets(payload.charsets)
			.setExportTemplateConfig(
				state.templateDetails.setPredictedFileName(payload.predicted)
			)
	),
	on(fetchExportTemplateListSuccess, (state, payload) =>
		state.setExportTemplateList(payload.elems)
	),
	on(executeExportUsingTemplate, (state) => state.setExportInProgress(true)),
	on(exportExecutionFinished, (state) => state.setExportInProgress(false)),
	on(closeExecuteExportUsingTemplateModal, (state) =>
		state
			.setExportInProgress(false)
			.setDatablockLabel('')
			.setDatablockId(undefined)
	),
	on(predictExportFileNameBasedOnPattern, (state, payload) =>
		state
			.setExportTemplateConfig(payload.currentConfig)
			.setHasDirtyFileNamePattern(true)
	),
	on(predictExportFileNameBasedOnPatternSuccess, (state, payload) =>
		state.setExportTemplateConfig(
			state.templateDetails.setPredictedFileName(payload.predicted)
		)
	),
	on(fetchAvailableTagsSuccess, (state, payload) => state.setTags(payload.tags))
);
