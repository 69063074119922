import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineAll } from '@dc-common-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs';

import { ProjectMemberRequester } from '../../requesters/core/project-member.requester';
import { ProjectRequester } from '../../requesters/core/project-requester';
import { OrphanProjectListEntity } from '../components/orphan-project-list/orphan-project-list.entity';
import { OrphanProjectParser } from '../orphan-project-parser';
import {
	initOrphanProjectsList,
	fetchOrphanProjectsFail,
	fetchOrphanProjectsSuccess,
	setOrphanProjectAdmin,
	refreshOrphanProjectsList,
} from './orphan-project.actions';

@Injectable()
export class OrphanProjectEffects {
	public orphanProjects$ = createEffect(() =>
		this.action$.pipe(
			ofType(initOrphanProjectsList, refreshOrphanProjectsList),
			switchMap(() =>
				combineAll({
					orphanProjects: this.projectRequester.getOrphanProjects(),
					allProjectsMembers:
						this.userRequester.getAvailableMembersFromAllProjects(),
				})
			),
			map((res) => {
				const list = OrphanProjectListEntity.build({
					orphanProjects: this.orphanProjectParser.parseOrphanProjects(
						res.orphanProjects
					),
					availableAdmins: this.orphanProjectParser.parseAdmins(
						res.allProjectsMembers
					),
				});
				return fetchOrphanProjectsSuccess({
					orphanProjectList: list,
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(
					fetchOrphanProjectsFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public setAdmin$ = createEffect(() =>
		this.action$.pipe(
			ofType(setOrphanProjectAdmin),
			switchMap((action) =>
				this.projectRequester.setOrphanProjectAdmins(action.id, [action.admin])
			),
			map(() => refreshOrphanProjectsList()),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(
					fetchOrphanProjectsFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public constructor(
		private readonly action$: Actions,
		private readonly projectRequester: ProjectRequester,
		private readonly userRequester: ProjectMemberRequester,
		private readonly store: Store,
		private readonly orphanProjectParser: OrphanProjectParser
	) {}
}
