import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RequestersSelector } from './store';

@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [RequestersSelector],
})
export class RequestersModule {}
