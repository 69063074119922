import { Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcBaseComponent } from '@dc-common-core';
import { AppModalService, ModalData } from '@dc-common-ui';

import { DcIcons } from '../../app-dc.icons';
import { AppTagItemEntity } from '../app-tag-configurator/app-tag-item.entity';

@Component({
	selector: 'app-tag-edit',
	templateUrl: './app-tag-edit.component.html',
	styleUrls: ['./app-tag-edit.component.scss'],
})
export class AppTagEditComponent<
	T extends { selected: AppTagItemEntity }
> extends DcBaseComponent {
	protected readonly Icons = DcIcons;
	public Appearance: MatFormFieldAppearance = 'outline';
	public acceptLabel = $localize`:i18n=@@general.edit.save:`;
	public cancelLabel = $localize`:i18n=@@general.edit.cancel:`;
	public tagLabel = '';
	public hideColorPicker = false;
	public hideTextInput = false;
	public allowedFormats = 'hex';
	public tagColor = '#008080';
	public colorPattern = '#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})';
	public LABEL_MAX_LEN = 50;

	@ViewChild(NgForm, {
		read: NgForm,
		static: false,
	})
	private readonly form!: NgForm;

	public constructor(
		@Inject(MAT_DIALOG_DATA) public readonly modalData: ModalData & T,
		private readonly modalService: AppModalService
	) {
		super();
		this.cmpId = 'tag-edit';
		this.tagLabel = this.modalData.selected.label;
		this.tagColor = this.modalData.selected.color;
	}

	public openColorPicker(): void {
		alert('opened');
	}

	public setNewLabel(newVal: string): void {
		this.tagLabel = newVal;
	}

	public setNewColor(newColor: string): void {
		this.tagColor = newColor;
	}

	public save(): void {
		if (this.form === null) {
			return;
		}
		const formStatus = this.form.status;
		if (formStatus === null || formStatus === 'INVALID') {
			return;
		}
		this.modalService.closeActiveModal({
			label: this.tagLabel,
			color: this.tagColor,
		});
	}

	public close(): void {
		this.modalService.closeActiveModal();
	}
}
