export enum ValidationErrorKeys {
	Required = 'required',
	Min = 'min',
	Max = 'max',
	Maxlength = 'maxlength',
	InvalidCron = 'invalid-cron',
	InvalidCronForm = 'cron-form-invalid',
	UnacceptedValue = 'unaccepted-value',
	MinimalAcceptedRecurrence = 'minimal-accepted-recurrence',
	ForbiddenCharacters = 'ForbiddenCharacters',
	UnacceptedCharacters = 'unaccepted-characters',
	LowerUpperBoundViolation = 'lower-upper-bound-violation',
	Duplicates = 'duplicate-values',
	ListItemOrdering = 'list-item-ordering',
	OutOfBounds = 'out-of-bounds',
	Pattern = 'pattern',
}
