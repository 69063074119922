import { createReducer, on } from '@ngrx/store';

import {
	initOrphanProjectsList,
	fetchOrphanProjectsSuccess,
} from './orphan-project.actions';
import { OrphanProjectState } from './orphan-project.state';

export const orphanProjectReducer = createReducer<OrphanProjectState>(
	new OrphanProjectState(),
	on(initOrphanProjectsList, (state) => state.setLoading(true)),
	on(fetchOrphanProjectsSuccess, (state, action) =>
		state.setLoading(false).setOrphanProjectList(action.orphanProjectList)
	)
);
