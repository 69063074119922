import { Injectable } from '@angular/core';
import { AppIcons, IIcons } from '@dc-common-ui';

export enum HdIcons {
	LineChart = 'Handle:chart-line',
	PieChart = 'Handle:chart-pie',
	BarChart = 'Handle:chart-bar',
}

@Injectable()
export class AppHdIcons extends AppIcons {
	private readonly path = './src/img';
	protected namespace = 'Handle';

	protected icons: IIcons = {
		[HdIcons.LineChart]: `${this.path}/hd/chart-bar-solid.svg`,
		[HdIcons.PieChart]: `${this.path}/hd/chart-line-solid.svg`,
		[HdIcons.BarChart]: `${this.path}/hd/chart-pie-solid.svg`,
	};
}
