import { IisEqual } from '@dc-common-core';
import { immerable, produce } from 'immer';

interface ITagEntity {
	id: number;
	position: number;
	label: string;
	color: string;
}

const entityDefaults: ITagEntity = {
	id: -1,
	label: '',
	color: '',
	position: -1,
};

export class AppTagItemEntity
	implements ITagEntity, IisEqual<AppTagItemEntity>
{
	private [immerable] = true;

	public id = entityDefaults.id;
	public label = entityDefaults.label;
	public color = entityDefaults.color;
	public position = entityDefaults.position;

	public static build(params: Partial<ITagEntity>): AppTagItemEntity {
		const instance = new AppTagItemEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.label = params.label ?? entityDefaults.label;
		instance.color = params.color ?? entityDefaults.color;
		instance.position = params.position ?? entityDefaults.position;
		return instance;
	}

	public setPosition(position: number): AppTagItemEntity {
		return produce(this, (draft) => {
			draft.position = position;
		});
	}

	public setLabel(label: string): AppTagItemEntity {
		return produce(this, (draft) => {
			draft.label = label;
		});
	}

	public setColor(color: string): AppTagItemEntity {
		return produce(this, (draft) => {
			draft.color = color;
		});
	}

	public isEqualTo(obj: AppTagItemEntity): boolean {
		return this.label.localeCompare(obj.label) === 0;
	}
}
