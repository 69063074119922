import { Injectable } from '@angular/core';
import { AjsInjectorService } from './ajs-injector.service';
import { firstValueFrom, map, Observable, shareReplay } from 'rxjs';

@Injectable()
export class AjsStateService {
	private readonly $state: Observable<any>;
	public params: { [index: string]: string } = {};

	public constructor(private readonly ajsInjectorService: AjsInjectorService) {
		this.$state = this.ajsInjectorService.$injector.pipe(
			map(($injector) => $injector.get('$state')),
			shareReplay(1)
		);
	}

	public async go(
		state: string,
		params?: { [index: string]: string },
		fullReload?: boolean
	): Promise<void> {
		const $state = await firstValueFrom(this.$state);
		return $state.go(state, params, { reload: !!fullReload });
	}
}
