import { OrphanProjectEntity } from './orphan-project.entity';
import { ProjectAdminEntity } from './project-admin.entity';

interface IOrphanProjectListEntity {
	orphanProjects: Array<OrphanProjectEntity>;
	availableAdmins: Array<ProjectAdminEntity>;
}

const entityDefaults: IOrphanProjectListEntity = {
	orphanProjects: [],
	availableAdmins: [],
};

export class OrphanProjectListEntity implements IOrphanProjectListEntity {
	public orphanProjects = entityDefaults.orphanProjects;
	public availableAdmins = entityDefaults.availableAdmins;

	public static build(
		params: Partial<IOrphanProjectListEntity> = {}
	): OrphanProjectListEntity {
		const instance = new OrphanProjectListEntity();
		instance.orphanProjects =
			params.orphanProjects ?? entityDefaults.orphanProjects;
		instance.availableAdmins =
			params.availableAdmins ?? entityDefaults.availableAdmins;
		return instance;
	}
	private constructor() {}
}
