interface IProjectAdminEntity {
	id: string;
	name: string;
}

const entityDefaults: IProjectAdminEntity = {
	id: '',
	name: '',
};

export class ProjectAdminEntity implements IProjectAdminEntity {
	public id = entityDefaults.id;
	public name = entityDefaults.name;

	public static build(
		params: Partial<IProjectAdminEntity> = {}
	): ProjectAdminEntity {
		const instance = new ProjectAdminEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.name = params.name ?? entityDefaults.name;
		return instance;
	}
	private constructor() {}
}
