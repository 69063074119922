import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
} from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcBaseComponent, combineAll } from '@dc-common-core';
import { Store } from '@ngrx/store';
import { Map, Set } from 'immutable';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	firstValueFrom,
	from,
	map,
	Observable,
	of,
	switchAll,
	switchMap,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs';

import { NgCommService } from '../../../ajs-ng-communication.service';
import { FunctionalDocumentationKeys } from '../../../core/documentation/functional-doc-keys';
import { GdIcons } from '../../../ui/app-gd.icons';
import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import {
	ExportTemplateSelector,
	predictExportFileNameBasedOnPattern,
} from '../../store';
import { ExportCharsetEntity } from './export-charset.entity';
import {
	ConnectorMode,
	ExportConnectorEntity,
} from './export-connector.entity';
import {
	ExportFormat,
	ExportTemplateDetailsEntity,
} from './export-template-details.entity';
import {
	ExportTemplateDetailsForm,
	ExportTemplateFormControls,
} from './export-template-details.form';
import { ExportTemplateDetailsHelper } from './export-template-details.helper';

interface IExportOutputFormatOption {
	label: string;
	value: ExportFormat;
}

@Component({
	selector: 'app-export-template-details',
	templateUrl: './export-template-details.component.html',
	styleUrls: ['./export-template-details.component.scss'],
	inputs: ['exportConfig'],
	providers: [ExportTemplateDetailsForm, ExportTemplateDetailsHelper],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportTemplateDetailsComponent extends DcBaseComponent {
	public GdIcons = GdIcons;
	public FunctionalDocumentationKeys = FunctionalDocumentationKeys;
	public ValidationErrorKeys = ValidationErrorKeys;
	public Appearance: MatFormFieldAppearance = 'outline';
	public ExportTemplateFormControls = ExportTemplateFormControls;
	public outputFormats: Array<IExportOutputFormatOption> = [
		{
			value: ExportFormat.CSV,
			label: 'CSV',
		},
		{
			value: ExportFormat.XML,
			label: 'XML',
		},
		{
			value: ExportFormat.JSON,
			label: 'JSON',
		},
		{
			value: ExportFormat.Parquet,
			label: 'Parquet',
		},
		{
			value: ExportFormat.Excel,
			label: 'Excel',
		},
	];

	@Output()
	public notifyOnError = new EventEmitter<boolean>();

	@Output()
	public hasChangedValue = new EventEmitter<boolean>();

	public vo$: Observable<{
		isLoading: boolean;
		isConfigurable: boolean;
		config: ExportTemplateDetailsEntity;
		connectors: Map<number, ExportConnectorEntity>;
		charsets: Set<ExportCharsetEntity>;
	}>;

	public constructor(
		private readonly store: Store,
		private readonly exportTemplateSelector: ExportTemplateSelector,
		public readonly exportTemplateForm: ExportTemplateDetailsForm,
		public readonly exportTemplateDetailsHelper: ExportTemplateDetailsHelper,
		public readonly ngCommService: NgCommService
	) {
		super();
		this.cmpId = 'export-template';

		this.toObservable<ExportTemplateDetailsEntity>('exportConfig')
			.pipe(
				takeUntil(this.onDestroy$),
				tap((config) => this.exportTemplateForm.populate(config))
			)
			.subscribe();

		this.exportTemplateForm.form.valueChanges
			.pipe(
				debounceTime(500), // prevents multiple executions on page startup due to form controls firing sequentially
				withLatestFrom(
					this.toObservable<ExportTemplateDetailsEntity>('exportConfig'),
					this.exportTemplateSelector.getHasDirtyFileNamePattern$()
				),
				map(([formValues, knownInputEntity, hasDirtyFileNamePattern]) => {
					if (knownInputEntity.id === -1) {
						// TODO replace with explicit instance saying it is in creation mode; i.e. all modifications is to be saved
						return true;
					}
					const isFile =
						formValues[ExportTemplateFormControls.ConnectorMode].type !==
							ConnectorMode.SQL &&
						formValues[ExportTemplateFormControls.ConnectorMode].type !==
							ConnectorMode.NoSQL &&
						formValues[ExportTemplateFormControls.ConnectorMode].type !==
							ConnectorMode.Neo4j;

					if (isFile && hasDirtyFileNamePattern) {
						return true;
					}
					return !knownInputEntity.isEqualTo(this.extractFormData());
				}),
				takeUntil(this.onDestroy$),
				tap((res) => this.hasChangedValue.next(res))
			)
			.subscribe();

		this.exportTemplateSelector
			.getPredictedFileName$()
			.pipe(
				takeUntil(this.onDestroy$),
				tap((val) => this.exportTemplateForm.updatePredictedFileName(val))
			)
			.subscribe();

		this.exportTemplateForm.form.statusChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap((status) => {
					this.notifyOnError.emit(status === 'INVALID');
				})
			)
			.subscribe();

		this.exportTemplateForm.form.valueChanges
			.pipe(
				debounceTime(750), // prevents multiple executions on page startup due to form controls firing sequentially
				takeUntil(this.onDestroy$),
				map((formValues) => formValues[ExportTemplateFormControls.Path]),
				withLatestFrom(
					this.exportTemplateDetailsHelper.pathValueDisabledAndChanged$
				),
				filter(
					([val, isPathChanged]) =>
						(val !== undefined && val !== null) || isPathChanged
				),
				distinctUntilChanged(),
				map(() => from(this.handleFilePatternChange())),
				switchAll()
			)
			.subscribe();

		this.vo$ = combineAll({
			isLoading: this.exportTemplateSelector.getLoading$(),
			isConfigurable: of(false).pipe(
				switchMap(() => this.exportTemplateForm.exportType.valueChanges),
				filter((t): t is ExportFormat => t !== null),
				map(
					(type) => type !== ExportFormat.Parquet && type !== ExportFormat.JSON
				)
			),
			config: this.exportTemplateSelector.getExportTemplateConfig$(),
			charsets: this.exportTemplateSelector.getCharsets$(),
			connectors: this.exportTemplateSelector.getAvailableConnectors$(),
		});
	}

	public async openPathExplorerModal(): Promise<void> {
		const selectedConnector = await firstValueFrom(
			of(this.exportTemplateForm.connector.value)
		);
		if (selectedConnector === null) {
			return;
		}
		if (
			selectedConnector.type !== ConnectorMode.S3 &&
			selectedConnector.type !== ConnectorMode.SFTP &&
			selectedConnector.type !== ConnectorMode.HDFS
		) {
			return;
		}
		let title = '';
		if (selectedConnector.type === ConnectorMode.S3) {
			title = $localize`:i18n=@@export.path.connector.S3:`;
		} else if (selectedConnector.type === ConnectorMode.SFTP) {
			title = $localize`:i18n=@@export.path.connector.SFTP:`;
		} else if (selectedConnector.type === ConnectorMode.HDFS) {
			title = $localize`:i18n=@@export.path.connector.HDFS:`;
		}
		const path = await this.ngCommService.openPathExplorerModal({
			title,
			connectorId: selectedConnector.id,
			type: selectedConnector.type,
		});
		if (path === undefined) {
			return;
		}
		this.exportTemplateForm.updatePath(path);

		await this.handleFilePatternChange();
	}

	public extractFormData(): ExportTemplateDetailsEntity {
		return this.exportTemplateForm.extract();
	}

	public async handleFilePatternChange(): Promise<void> {
		const currentConfig = this.extractFormData();
		const inMemoryConfig = await firstValueFrom(
			this.exportTemplateSelector.getExportTemplateConfig$()
		);
		const isFile =
			currentConfig.connector.type !== ConnectorMode.SQL &&
			currentConfig.connector.type !== ConnectorMode.NoSQL &&
			currentConfig.connector.type !== ConnectorMode.Neo4j;
		if (
			isFile &&
			currentConfig.fileNameConfig.userDefinedLabel !==
				inMemoryConfig.fileNameConfig.userDefinedLabel
		) {
			return this.store.dispatch(
				predictExportFileNameBasedOnPattern({
					currentConfig,
				})
			);
		}
		if (isFile && currentConfig.path !== inMemoryConfig.path) {
			return this.store.dispatch(
				predictExportFileNameBasedOnPattern({
					currentConfig,
				})
			);
		}
	}
}
