import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { IAppRootState } from '../../core/store';
import { FormulaUsageListEntity } from '../components/formula-usage-list/formula-usage-list.entity';
import { FormulaFeatureKey, FormulaState } from './formula.state';

interface FormulaRootState extends IAppRootState {
	[FormulaFeatureKey]: FormulaState;
}

@Injectable()
export class FormulaSelector {
	public constructor(private readonly store: Store<FormulaRootState>) {}

	private get state(): Observable<FormulaState> {
		return this.store.pipe(select((store) => store[FormulaFeatureKey]));
	}
	public getLoading$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('loading')));
	}

	public getFormulaUsages$(): Observable<FormulaUsageListEntity> {
		return this.state.pipe(map((state) => state.get('list')));
	}

	public getUsageCount$(): Observable<number> {
		return this.state.pipe(map((state) => state.get('allUsageCount')));
	}
}
