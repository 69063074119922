import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ExportTemplateModule } from '../export-template/export-template.module';
import { FormulaModule } from '../formula/formula.module';

@NgModule({
	declarations: [],
	imports: [CommonModule, ExportTemplateModule, FormulaModule],
})
export class MiscellaneousModule {}
