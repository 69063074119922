import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { ExportTemplateSelector } from '../../store';
import { WriteMode } from '../export-write-mode-configuration/export-write-mode-configuration.entity';
import { ConnectorMode } from './export-connector.entity';

@Injectable()
export class ExportTemplateDetailsHelper {
	private readonly selectedWriteModeSubject = new ReplaySubject<WriteMode>(1);
	public readonly selectedWriteMode$ =
		this.selectedWriteModeSubject.asObservable();

	private readonly selectedConnectorTypeSubject =
		new ReplaySubject<ConnectorMode>(1);
	public readonly selectedConnectorType$ =
		this.selectedConnectorTypeSubject.asObservable();

	private readonly pathValueDisabledAndChangedSubject =
		new ReplaySubject<boolean>(1);
	public pathValueDisabledAndChanged$ =
		this.pathValueDisabledAndChangedSubject.asObservable();

	public constructor(
		private readonly exportTemplateSelector: ExportTemplateSelector
	) {}

	public setSelectedWriteMode(val: WriteMode): void {
		this.selectedWriteModeSubject.next(val);
	}

	public setSelectedConnectorType(val: ConnectorMode): void {
		this.selectedConnectorTypeSubject.next(val);
	}

	public getSelectedDatablockLabel$(): Observable<string> {
		return this.exportTemplateSelector.getSelectedDatablockLabel$();
	}

	public setPathValueDisabledAndChanged(isChanged: boolean): void {
		this.pathValueDisabledAndChangedSubject.next(isChanged);
	}
}
