import { Injectable } from '@angular/core';
import { CachePolicy, CachePolicyEntity, UrlService } from '@dc-common-core';
import { Observable } from 'rxjs';

import { DcBaseRequester } from './dc-base.requester';
import { IProjectMemberModel } from './project-member.model';

@Injectable({
	providedIn: 'root',
})
export class ProjectMemberRequester extends DcBaseRequester<IProjectMemberModel> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;
	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('USERS_BASIC');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public getAvailableMembersFromAllProjects(): Observable<
		ReadonlyArray<IProjectMemberModel>
	> {
		const url = this.urlService.generateUrl(this.resource, {}, {});
		return this.get<Array<IProjectMemberModel>>(
			url,
			CachePolicyEntity.build({
				isCacheable: true,
				evictTimeout: 1 * 60,
				policy: CachePolicy.CacheOnly,
			})
		);
	}
}
