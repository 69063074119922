<div class="dx-viewport" *ngIf="vo$ | async; let vo">
	<p i18n="@@test">First Angular Component</p> {{ vo.currentDate }}

<!--	<app-test-user [name]="vo.currentTime"></app-test-user>-->

	<app-tooltip tooltipText="Test" class="test-color-import">Test</app-tooltip>

	<button (click)="openModal()">Open Modal</button>
	<!--	Custom Icon Library -->
	<!--	<div>-->
<!--		<mat-icon-->
<!--			[svgIcon]="DcIcons.Common"-->
<!--			matTooltip="Common icon"-->
<!--			matTooltipPosition="right"-->
<!--		></mat-icon>-->

<!--		<mat-icon [svgIcon]="GdIcons.PersistInDisk"></mat-icon>-->

<!--		<mat-icon [svgIcon]="HdIcons.BarChart"></mat-icon>-->
<!--	</div>-->

<!-- DevExtreme notifier vallier	-->
<!--	<dx-button-->
<!--		stylingMode="contained"-->
<!--		text="Test me"-->
<!--		type="success"-->
<!--		[width]="150"-->
<!--		(onClick)="notify($event)"-->
<!--	>-->
<!--	</dx-button>-->

<!--	DevExtreme char test-->
<!--	<ng-container>-->
<!--		<h6>{{ vo.translatedString }}</h6>-->
<!--		<dx-chart-->
<!--			class="demo-dx-chart"-->
<!--			id="chart"-->
<!--			title="Daily Temperatures"-->
<!--			[dataSource]="vo.temperaturesData"-->
<!--			[customizePoint]="customizePoint"-->
<!--			[customizeLabel]="customizeLabel"-->
<!--		>-->
<!--			<dxi-series-->
<!--				argumentField="day"-->
<!--				valueField="value"-->
<!--				type="bar"-->
<!--				color="#e7d19a"-->
<!--			></dxi-series>-->
<!--			<dxi-value-axis [maxValueMargin]="0.01" [visualRange]="{ startValue: 0 }">-->
<!--				<dxo-label [customizeText]="customizeText"></dxo-label>-->
<!--				<dxi-constant-line-->
<!--					[width]="2"-->
<!--					[value]="lowTemp"-->
<!--					color="#8c8cff"-->
<!--					dashStyle="dash"-->
<!--				>-->
<!--					<dxo-label text="Low Average"></dxo-label>-->
<!--				</dxi-constant-line>-->
<!--				<dxi-constant-line-->
<!--					[width]="2"-->
<!--					[value]="highTemp"-->
<!--					color="#ff7c7c"-->
<!--					dashStyle="dash"-->
<!--				>-->
<!--					<dxo-label text="High Average"></dxo-label>-->
<!--				</dxi-constant-line>-->
<!--			</dxi-value-axis>-->
<!--			<dxo-legend [visible]="false"></dxo-legend>-->
<!--			<dxo-export [enabled]="true"></dxo-export>-->
<!--		</dx-chart>-->
<!--	</ng-container>-->
</div>
