import { Injectable } from '@angular/core';
import { OrderedSet } from 'immutable';

import { IMetadataTag } from '../requesters/core/metadata.model';
import { IDcTag } from '../requesters/core/tag.model';
import { AppTagItemEntity } from '../ui/components/app-tag-configurator/app-tag-item.entity';

@Injectable({
	providedIn: 'root',
})
export class TagAdapter {
	public generateMetadataTagPayload(
		templateTags: Array<AppTagItemEntity>
	): Array<IMetadataTag> {
		return templateTags.map((t) => ({
			id: t.id !== -1 ? t.id : undefined,
			code: t.label,
			color: t.color,
			position: t.position,
		}));
	}

	public generateResponseMetadataTag(
		tags: ReadonlyArray<IMetadataTag>
	): OrderedSet<AppTagItemEntity> {
		const availableTags = tags
			.map<AppTagItemEntity>((t) =>
				AppTagItemEntity.build({
					id: t.id,
					label: t.code,
					color: t.color,
					position: t.position,
				})
			)
			.sort((t1, t2) => t1.position - t2.position);
		return OrderedSet(availableTags);
	}

	public generateTags(
		tags: ReadonlyArray<IDcTag> | undefined
	): OrderedSet<AppTagItemEntity> {
		if (tags === undefined) {
			return OrderedSet();
		}
		const availableTags = tags
			.map<AppTagItemEntity>((t) =>
				AppTagItemEntity.build({
					id: t.id,
					label: t.code,
					color: t.color,
				})
			)
			.sort((t1, t2) =>
				t1.label.toLowerCase().localeCompare(t2.label.toLowerCase())
			);
		return OrderedSet(availableTags);
	}
}
