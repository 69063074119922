import { IisEqual } from '@dc-common-core';
import { immerable, produce } from 'immer';

export enum FileNameGenStrategy {
	UUID = 'uuid',
	DatablockLabelDateTime = 'date-time-datablock-label',
	FileNameDateTime = 'date-time-filename',
	UserDefinedFileNamePattern = 'user-defined-pattern',
}

interface IExportFileConfigurationEntity {
	strategy: FileNameGenStrategy;
	userDefinedLabel: string | undefined;
	predicted: string;
}

const entityDefaults: IExportFileConfigurationEntity = {
	strategy: FileNameGenStrategy.UUID,
	userDefinedLabel: undefined,
	predicted: '',
};

export class ExportFileConfigurationEntity
	implements
		IExportFileConfigurationEntity,
		IisEqual<ExportFileConfigurationEntity>
{
	private [immerable] = true;
	public strategy = entityDefaults.strategy;
	public userDefinedLabel = entityDefaults.userDefinedLabel;
	public predicted = entityDefaults.predicted;

	public static build(
		params: Partial<IExportFileConfigurationEntity> = {}
	): ExportFileConfigurationEntity {
		const instance = new ExportFileConfigurationEntity();
		instance.strategy = params.strategy ?? entityDefaults.strategy;
		instance.userDefinedLabel =
			params.userDefinedLabel ?? entityDefaults.userDefinedLabel;
		instance.predicted = params.predicted ?? entityDefaults.predicted;
		return instance;
	}
	private constructor() {}

	public isEqualTo(obj: ExportFileConfigurationEntity): boolean {
		if (this.strategy.localeCompare(obj.strategy) !== 0) {
			return false;
		}
		if (
			this.strategy === FileNameGenStrategy.UserDefinedFileNamePattern &&
			this.userDefinedLabel !== undefined &&
			obj.userDefinedLabel !== undefined
		) {
			if (this.userDefinedLabel.localeCompare(obj.userDefinedLabel) !== 0) {
				return false;
			}
		}
		return true;
	}

	public setPredictedUserDefinedLabel(
		pattern: string
	): ExportFileConfigurationEntity {
		return produce(this, (draft) => {
			draft.predicted = pattern;
		});
	}
}
