import { Component } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { combineAll, DcBaseComponent } from '@dc-common-core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';

import {
	initOrphanProjectsList,
	setOrphanProjectAdmin,
} from '../../store/orphan-project.actions';
import { OrphanProjectSelector } from '../../store/orphan-project.selector';
import { OrphanProjectListEntity } from './orphan-project-list.entity';

@Component({
	selector: 'app-orphan-project-list',
	templateUrl: './orphan-project-list.component.html',
	styleUrls: ['./orphan-project-list.component.scss'],
})
export class OrphanProjectListComponent extends DcBaseComponent {
	private readonly projectAdminSubject = new BehaviorSubject<
		Map<number, string>
	>(new Map());
	public Appearance: MatFormFieldAppearance = 'outline';

	public vo$: Observable<{
		isLoading: boolean;
		orphanProjectList: OrphanProjectListEntity;
		projectAdminMap: Map<number, string>;
	}>;
	public constructor(
		private readonly store: Store,
		private readonly projectSelector: OrphanProjectSelector
	) {
		super();
		this.cmpId = 'orphan-project-list';
		this.store.dispatch(initOrphanProjectsList());

		this.vo$ = combineAll({
			isLoading: this.projectSelector.getLoading$(),
			orphanProjectList: this.projectSelector.getOrphanProjectList$(),
			projectAdminMap: this.projectAdminSubject,
		}).pipe(
			map(({ isLoading, orphanProjectList, projectAdminMap }) => ({
				isLoading,
				orphanProjectList,
				projectAdminMap,
			}))
		);
	}

	public async onSelectionChange(
		projectId: number,
		adminId: string
	): Promise<void> {
		const projectAdminMap = await firstValueFrom(this.projectAdminSubject);
		const updated = projectAdminMap.set(projectId, adminId);
		this.projectAdminSubject.next(updated);
	}

	public async save(id: number): Promise<void> {
		const projectAdminMap = await firstValueFrom(this.projectAdminSubject);
		const adminId = projectAdminMap.get(id);
		if (adminId) {
			this.store.dispatch(
				setOrphanProjectAdmin({
					id,
					admin: adminId,
				})
			);
		}
	}
}
