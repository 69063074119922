<ng-container *ngIf="vo$ | async; let vo">
	<app-page>
		<app-page-header>
			<h2>{{vo.templateMetadata.title}}</h2>
		</app-page-header>
		<app-page-content class="export-template-content">
			<app-form-card headerTitle="generalConfigTitle"
										 i18n-headerTitle="@@general.metadata">
				<app-metadata cardContent
					[metadata]="vo.templateMetadata"
					(hasChangedValue)="hasMetaDataChangedValue($event)"
					(notifyOnError)="onMetadataError($event)">
					<app-tag-configurator [configuredTags]="vo.templateMetadata.tags | toArray"
																[availableTags]="vo.availableTags | toArray"
																(hasChangedValue)="hasTagsListChanged($event)">
					</app-tag-configurator>
				</app-metadata>
			</app-form-card>

			<app-export-template-details
				[exportConfig]="vo.templateDetails"
				(hasChangedValue)="hasDetailsChangedValue($event)"
				(notifyOnError)="onExportDetailsError($event)">
			</app-export-template-details>
		</app-page-content>

		<app-page-actions class="export-template-actions">
			<button
				id="{{cmpId + '-back-btn'}}"
				(click)="exitPage()"
				i18n="@@export.goback"
				mat-raised-button>
				Retour
			</button>

			<button
				id="{{cmpId + '-save-btn'}}"
				*ngIf="!vo.isNewTemplate"
				[disabled]="vo.isSaveDisabled"
				(click)="save()"
				i18n="@@export.save"
				mat-raised-button
				color="primary">
					Save
			</button>
			<button
				id="{{cmpId + '-save-btn'}}"
				*ngIf="vo.isNewTemplate"
				[disabled]="vo.isSaveDisabled || !vo.isModifiedByUser"
				(click)="save()"
				i18n="@@export.applyChanges"
				mat-raised-button
				color="primary">
					Apply Changes
			</button>
		</app-page-actions>
	</app-page>
</ng-container>
